<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-log-layout">
      <div class="gl-page-header" style="padding: 25px 11px 0 11px;">
        <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
      </div>
      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-1">
              <div @click="$router.push('/inviteFriend')" style="display: flex; justify-content: center; align-items: center; background: rgba(40, 37, 56, 1); width: 80px; height: 33px; ">
                  <img src="@/assets/images/index/arrowIcon2.png" style="max-width: 18px; height: auto; margin-right: 6px" />
                  <div style="color: rgba(164, 159, 191, 1); font-weight: 700; letter-spacing: 1.46px; font-size: 15px">{{$t('all.i_back')}}</div>
              </div>
        </div>
      </div>

      
      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-2">
          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div v-for="log in inviteLog" :key="log.id" style="background-color: rgba(40, 41, 66, 1);margin-bottom: 8px;padding: 22px;border-radius: 8px;">
              <div style="overflow: hidden;color: rgba(0, 0, 0, 1);">
                <div style="float: left;margin-top: 3px;font-size: 26px;">
                  <div  style="float: left; position: relative; top: 5px; color: rgba(80, 214, 119, 1);">+{{log.reward}}</div>
                  <img v-if="log.reward == 0" src="@/assets/common/cashIcon.png" style="margin-left: -50px;width: 24px; position: relative; top: 6px; " />
                  <img v-else src="@/assets/common/cashIcon.png" style="margin-left: 4px;width: 24px; position: relative; top: 6px;" />
                  <div v-if="log.reward == 0" style="color: rgba(80, 214, 119, 1); font-size: 12px; margin-top: 5px">Step 2 not completed</div>
                  <div v-else style="visibility: hidden;color: rgba(80, 214, 119, 1); font-size: 12px; margin-top: 5px">Step 2 not completed</div>
                </div>
                
                <div style="float: right;text-align: right;color: rgba(255, 255, 255, 1);">
                  {{$t('leaderboard.i_friends')}}UID {{log.uid}}
                  <div style="margin-top: 10%; color: rgba(174, 175, 177, 1);font-size: 13px;">
                    {{log.date}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="inviteLog == ''">
            <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
            <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{failMessage}}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        cashNum: 0,
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      busy: false,
      pageNum: 0,
      isLast: false,
      inviteLog: []
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;
        this.$axios({
          url: "/webCash/invite/log",
          method: "get",
          params: {
            pageNum: this.pageNum,
            
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            if (this.pageNum == 1) {
              this.userInfo = result.data.data.userInfo;
              this.$store.state.cashNum = result.data.data.userInfo.num;
            }
             result.data.data.items.forEach((element) => {
              this.inviteLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
    }
  },
  created() {
    cashNum = $store.state.cashNum
  }
};
</script>

<style>
#invite-log-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}
#invite-log-layout .gl-page-header {
  overflow: hidden;
  background-color: rgba(28, 29, 47, 1)
}

@media screen and (min-width: 1280px){
  #invite-log-layout .main-1{
    display: flex; 
    justify-content: left; 
    align-items: center; 
    margin-top: 35px; 
    width: 1200px;
    /* margin-left: -1055px */
  }
  #invite-log-layout .main-2{
      margin-top: 25px;
      width: 1200px;
      overflow-y: scroll;
      height: 84vh;
    }
}

@media screen and (max-width: 1280px){
    
    #invite-log-layout .main-1{
      display: flex; 
      justify-content: left; 
      align-items: center; 
      margin-top: 20px; 
      width: 92%;
      /* margin-bottom: 20px; */
    }
    #invite-log-layout .main-2{
      margin-top: 25px;
      width: 92%;
      overflow-y: scroll;
      height: 84vh;
    }
}


</style>
