<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-log-layout">
      <div class="cl-page-header" style="padding: 25px 11px 0 11px;">
        <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-1" >
              <div @click="$router.push('/redeem')" style="display: flex; justify-content: center; align-items: center; background: rgba(40, 37, 56, 1); width: 80px; height: 33px; ">
                  <img src="@/assets/images/index/arrowIcon2.png" style="max-width: 18px; height: auto; margin-right: 6px" />
                  <div style="color: rgba(164, 159, 191, 1); font-weight: 700; letter-spacing: 1.46px; font-size: 15px">{{$t('all.i_back')}}</div>
              </div>
              <div style="text-align: left;font-size: 14px; color: rgba(164, 159, 191, 1);">{{$t('convert.i_credited')}}</div>

        </div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-2">
          <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div v-for="log in convertLog" :key="log.id" style="background-color: rgba(40, 41, 66, 1);margin-bottom: 8px;padding: 13px;border-radius: 8px;">
              <div style="overflow: hidden;">
                <div style="float: left;font-size: 22px;color: black; color: rgba(80, 214, 119, 1);">
                  {{log.price}}
                </div>
                <div style="float: right;">
                  <span v-if="log.status == 1" style="color: rgba(255, 255, 255, 1);">
                    {{$t('convert.i_reviewSucceeded')}}
                  </span>
                  <span v-else-if="log.status == 0" style="color: rgba(80, 214, 119, 1);">
                    {{$t('convert.i_underReview')}}
                  </span>
                  <span v-else-if="log.status == 2" style="color: red;">
                    {{$t('convert.i_auditFailure')}}
                  </span>
                  <span v-else-if="log.status == 3" style="color: red;">
                    {{$t('convert.i_invalid')}}
                  </span>
                </div>
              </div>
              <div style="overflow: hidden;font-size: 13px;color: black;">
                <div style="float: left;margin-top: 10px;font-size: 18px; color: rgba(255, 255, 255, 1);">
                  {{log.cardName}}
                </div>
                <div style="float: right;margin-top: 10px; color: rgba(174, 175, 177, 1);">
                  <span>{{$t('convert.i_applicationPeriod')}}</span>
                  {{log.time}}
                </div>
              </div>
              <div v-if="log.status == 2||log.status == 3" style="margin-top: 5px;text-align: left;font-size: 12px;color: red;">
                {{$t('convert.i_remark')}}：{{log.remark}}
              </div>
              <div v-else style="visibility: hidden;margin-top: 5px;text-align: left;font-size: 12px;color: red;">
                {{$t('convert.i_remark')}}
              </div>
            </div>
          </div>

          <div v-if="convertLog==''">
            <img src="@/assets/common/noIcon.png" style="margin-top: 100px;width: 150px;" />
            <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ convertLogParam.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      checkDialog: false,
      convertLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      convertLogParam: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.convertLogParam.check == "login") {
        window.location.href = this.convertLogParam.appScheme + "login";
      } else {
        window.location.href = this.convertLogParam.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;

        this.isLoading = true;
        this.$axios({
          url: "/webCash/convertLog",
          method: "get",
          params: {
            pageNum: this.pageNum,
            recordType: -1
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            if (this.pageNum == 1) {
              this.userInfo = result.data.data.userInfo;
              this.$store.state.cashNum = result.data.data.userInfo.num;
            }
            result.data.data.log.forEach((element) => {
              this.convertLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
      }
    }
  },
  created() {
    this.isLoading = true;
  }
};
</script>

<style>
#convert-log-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}
#convert-log-layout .cl-page-header {
  overflow: hidden;
  background-color: rgba(28, 29, 47, 1)
}

@media screen and (max-width: 1280px){
 #convert-log-layout .main-1{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-top: 22px; 
    /* margin-right: 24px; */
    /* margin-bottom: 20px; */
    /* margin-left: 20px; */
    width: 92%;
  }
  #convert-log-layout .main-2{
    margin-top: 15px;
    width: 92%;
    overflow-y: scroll;
    height: 83vh;
  }
 }

 @media screen and (min-width: 1281px) {
  #convert-log-layout .main-1{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-top: 35px; 
    margin-bottom: 10px;
    width: 1200px;
  }
  #convert-log-layout .main-2{
    margin-top: 15px;
    width: 1200px;
    overflow-y: scroll;
    height: 83vh;
  }
 }

 
 
</style>
