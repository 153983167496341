<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="gold-log-layout1">
      <div class="index-top">
        <!-- 导航条 -->
        <div class="i-page-header" style="padding: 25px 11px 0 11px;">
          <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <span class="top-button">
              <div class="top-button1" style="display: flex; align-items: center; flex-wrap: nowrap;">
                  <span @click="$router.push('/index')" class="main-0">
                      <img src="@/assets/images/index/indexIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_home')}}</div>
                  </span>
                  
                  <span @click="$router.push('/redeem')" class="main-0">
                      <img src="@/assets/images/index/exchangeIcon.png" style="width: 18px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_redeem')}}</div>
                  </span>
                  
                  <span @click="$router.push('/earn')" class="main-0">
                      <img src="@/assets/images/index/earnIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_earnMoney')}}</div>
                  </span>
                  
                  <span @click="$router.push('/inviteFriend')" class="main-0" >
                      <img src="@/assets/images/index/inviteIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_invite')}}</div>
                  </span>
                  
                  <span @click="$router.push('/personal')" style="background-color: rgba(80, 270, 119, 1);display: flex; flex-direction: column; align-items: center;display: flex; flex-direction: column; align-items: center; justify-content: center; width: 90px; height: 49px;">
                      <img src="@/assets/images/index/accountIcon1.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(0, 0, 0, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_account')}}</div>
                  </span>
              </div>
          </span>
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 6px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/account')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
        </div>

      
        <div style="margin-top: 10px">
          <swiper :options="swiperOption1"  class="my-swiper">
            <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <div style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Gabriel...</div>
                  </div>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Alexan...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">40PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlott...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/linePayIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">LinePay</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Mia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">10TWD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Victor...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/trueMoneyIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-left: -5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">True Money</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Sophia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">50THB</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlo...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">150PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1"  />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Benja...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
                      
          </swiper>
        </div>


      <!-- <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p> -->
      <!-- <div style="position: relative; top: 32px;margin-left: 5%;margin-right: 5%; scroll;height: 5vh;">
          <span style="color: rgba(255, 255, 255, 1); float: left; font-size: 19px">{{$t('index.i_personal')}}</span>
          <img src="@/assets/common/return.png" @click="$router.push('/index')" style="float: right;width: 30px;margin-right: 10px;" />
      </div> -->
      
      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; margin-top: 30px">
        <div class="main-1" >
          <div class="main-1-1">
              <div style="width: 60px;height: 60px; margin-top: 43px; margin-left: 6%">
                <img src="@/assets/images/index/avatar.png" style="width: 60px;height: 60px;border-radius: 30px;" />
                <img :src="userInfo.avatar" style="width: 60px;height: 60px;border-radius: 30px;margin-left: -60px;" />
              </div> 
              <div style="width: 100px;height: 60px; margin-top: 19px; margin-left: 6%">
                <div style="color: rgba(255, 255, 255, 1);text-align: left; font-size: 23px;font-weight: 400;margin-bottom: 10px;">{{ userInfo.userName }}</div>
                <div style="margin-top: 3px">
                  <td style="color: rgba(34, 35, 56, 1); background-color: rgba(83, 214, 120, 1);border-radius: 15px; width: 35px; ">UID</td>
                  <td style="text-align: left; color: rgba(255, 255, 255, 1); position: relative; left: 15px;">{{ userInfo.uid }}</td>
                </div>
              </div> 
              <p class="main-1-1-1"></p> 

              <div style="margin-top: 40px; margin-left: 6%"> 
                <div style="display: flex; align-items: center;justify-content: space-between; margin-right: 9%;">
                  <div style="display: flex; align-items: center;">
                    <div style="display: flex; align-items: center; justify-content: center;background-color: rgba(36, 72, 72, 1); width: 55px; height: 52px; border-radius: 17px;">
                        <img src="@/assets/images/index/manageIcon.png" style="width: 20px; vertical-align: middle;" />
                    </div>
                    <div class="main-1-2-1">
                      <div style="text-align: left;font-size: 21px; text-decoration: none; color: rgba(246, 243, 254, 1); font-weight: 600;">
                          {{ $store.state.cashNum }} <img src="@/assets/common/cashIcon.png" style="width: 20px; vertical-align: middle;" />
                      </div>
                      <div style="text-align: left; font-size: 15px;color: rgba(203, 204, 225, 1);font-weight: 400;">{{$t('index.i_currentGold')}}</div>
                    </div>
                  </div>
                  <div  @click="$router.push('/redeem')" style="display: flex; justify-content: center;font-weight: 700;letter-spacing: 0.4px;line-height: 52px;color: rgba(1,1,1,1);float: right;display: flex; align-items: center; justify-content: center;background-color: rgba(81, 215, 120, 1); width: 73px; height: 42px; border-radius: 10px; font-size: 14px">
                      {{$t('index.i_redeem')}}
                  </div>
                </div>

              </div> 
          </div>
          <div style="display: flex; align-items: center; justify-content: center">
                  <div @click="$router.push('/cashLog')" class="main-1-2-2">{{$t('all.i_checkTheDetails')}}</div>
              </div>
        </div>

        <div @click="openTelegram()" class="main-4-1">
            <img src="@/assets/images/index/communityIcon.png" style="max-width: 38px; height: auto; margin-bottom: 20px;" />
            <div style="color: rgba(255,255,255,1);font-weight: 400;letter-spacing: 2.61px;">
              {{$t('index.i_joinTheOfficialCommunity')}}
              <img src="@/assets/images/index/arrowIcon1.png" style="width: 19px; height: auto; " />
            </div>
        </div>

      </div>

      <div style="margin: auto; display: flex; justify-content: center; align-items: center; flex-direction: column; background-color: rgba(29, 30, 48, 1); height: 138px; margin-top: 320px; margin-bottom: 50px">
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 2.09px; margin-bottom: 12px; font-size: 15px;">
              <span @click="openPolicy()" style="">《{{$t('index.i_privacyPolicy')}}》</span>
              <span @click="openService()" style="">《{{$t('index.i_termsOfService')}}》</span>
          </div>
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1px; font-size: 12px">
            © 2020 - 2024 BIgbig Cash 2. All rights reserved.
          </div>
        </div>
      <div style="height: 1vh;">
      </div>
    </div>
    
     
  </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{failMessage}}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        cashNum: 0,
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      telegram: "https://t.me/+6iNEoHf2HaYxM2Zl",
      cashLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      maxReward: 0,
      swiperOption1: {
         slidesPerView: 'auto',
         spaceBetween: -70,
         loop: true,
         grid: {
            fill: 'column',
            rows: 1,
         },
         speed:9500,
         freeMode:true,

            autoplay: {

              delay:0,
              disableOnInteraction: false,
            },
        loopAdditionalSlides: 1
      },
    };
  },
  methods: {

    openService() {
      window.open(window.location.origin+"/BigBigSoftTermsOfService.html", "_blank");
    },

    openPolicy() {
      window.open(window.location.origin+"/BigBigSoftPrivacyPolicy.html", "_blank");
    },

    openTelegram() {
      window.open(this.telegram, "_blank");
    },

    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    getIndex() {
      
      this.isLoading = true;
      this.$axios({
        url: "/webCash/index",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.maxReward = result.data.data.maxReward;
          this.userInfo = result.data.data.userInfo;
          // this.failMessage = result.data.data.failMessage;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.todayCashLeaderboard =
            result.data.data.leaderboard.todayCashLeaderboard;
          if (result.data.data.leaderboard.todayCashLeaderboard == null) {
            this.leaderboardType = 2;
          }
          this.cashLeaderboard = result.data.data.leaderboard.cashLeaderboard;
          this.friendLeaderboard =
            result.data.data.leaderboard.friendLeaderboard;
          if (result.data.data.userInfo != null) {
            sessionStorage.setItem(
              "userInfo",
              JSON.stringify(result.data.data.userInfo)
            );
          } else {
            sessionStorage.removeItem("userInfo");
          }
        }
      });
    },
  },
  created() {
    this.getIndex()
  }
};
</script>

<style>
#gold-log-layout1 {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}
#index-layout1 .index-top {
  padding-bottom: 2%;
}
#gold-log-layout1 .i-page-header {
  overflow: hidden;
  
  background-color: rgba(28, 29, 47, 1)
}

/* @media screen and (min-width: 1080px) {
  #gold-log-layout1 .top-button{
    
    float: left;
    width: 369px; 
    margin-bottom: -2px;
    background: rgba(19, 21, 34, 1);
    height: 70px; 
    margin-top: -20px; 
    margin-left: 22px;
  }
  #gold-log-layout1 .top-button1{
    display: flex; 
    align-items: center; 
    margin-left: 27px; 
    margin-top: 17px
  }

  
  
} */

@media screen and (max-width: 1280px) {
  /* #gold-log-layout1 .top-button{
    float: left;
    width: 1280px;
    margin-bottom: 2px;
    background: rgb(19, 21, 34);
    height: 62px;
    margin-left: -720px;
    position: fixed;
    z-index: 2;
    bottom: -3px;
  }
  #gold-log-layout1 .top-button1{
    display: flex; 
    align-items: center; 
    margin-left: 488px; 
    margin-top: 11px
  } */
  #gold-log-layout1 .main-4-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 92%; 
    height: 160px; 
    margin-bottom: 20px;
    border-radius: 18px;
  }

  #gold-log-layout1 .main-1{
    background-color: rgba(34, 35, 56, 1);
    margin-bottom: 15px;
    border-radius: 10px; 
    height: 450px; 
    width: 92%;
  }
  #gold-log-layout1 .main-1-1{
    margin-left: 0px
  }
  #gold-log-layout1 .main-1-1-1{
    border: 1px solid rgba(71, 72, 92, 1); 
    width: 83%; 
    margin-top: 53px; 
    margin-left: 7.8%;
  }
  #gold-log-layout1 .main-1-2-1{
    margin-left: 10px; 
    width: 157px;
  }
  #gold-log-layout1 .main-1-2-2{
    display: flex; 
    align-items: center; 
    justify-content: center; 
    color:rgba(255,255,255,1); 
    font-weight: 500;
    letter-spacing: 1.73px;
    margin-top: 40px; 
    margin-left: 0px; 
    background-color: rgba(47, 48, 69, 1); 
    width: 295px; 
    height: 48px; 
    border-radius: 10px;
    font-size: 15px;
  }
}

@media screen and (min-width: 1280px) {
  #gold-log-layout1 .my-swiper {
  width: 1200px; /*设置Swiper的宽度*/
  overflow: hidden; /* 隐藏溢出的内容 */
  margin: auto; /* 水平居中 */
  }
  #gold-log-layout1 .main-4-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 1200px; 
    height: 160px; 
    margin-top: 10px;
    border-radius: 18px;
  }
  #gold-log-layout1 .main-1{
    background-color: rgba(34, 35, 56, 1);
    /* margin-bottom: 6%; */
    border-radius: 10px; 
    height: 450px; 
    width: 1200px;
  }
  #gold-log-layout1 .main-1-1{
    margin-left: 45px
  }
  #gold-log-layout1 .main-1-1-1{
    border: 1px solid rgba(71, 72, 92, 1); 
    width: 980px; 
    margin-top: 53px; 
    margin-left: 66px
  }
  #gold-log-layout1 .main-1-2-1{
    margin-left: 10px; 
    width: 610px;
  }
  #gold-log-layout1 .main-1-2-2{
    display: flex; 
    align-items: center; 
    justify-content: center; 
    color:rgba(255,255,255,1); 
    font-weight: 500;
    letter-spacing: 1.73px;
    margin-top: 40px; 
    /* margin-left: 220px;  */
    background-color: rgba(47, 48, 69, 1); 
    width: 300px; 
    height: 48px; 
    border-radius: 10px;
    font-size: 16px;
  }
  
}
#gold-log-layout1 .swiper{
  width:230px !important;
  
}

/* #gold-log-layout1 .my-swiper .swiper-slide:not(.swiper-slide-active) {
    opacity: 0.5; 
    
} */

</style>
