import Vue from "vue";
import axios from "axios";

import router from "@/router";
import { Message } from "element-ui";

let config = {
  baseURL: "/api",
  baseURL: "https://bs.yohooinc.com", // 线上服务域名
  // baseURL: "http://192.168.2.134:8020", // 本地服务域名
  timeout: 30 * 1000 // Timeout
};

const axiosServer = axios.create(config);

// 统一请求处理
axiosServer.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    config.headers["appId"] = "YGEnknaCm6n+FjrTbJXyWA==";

    let language = navigator.language;
    if (
      language == "zh-CN" ||
      language == "zh-MO" ||
      language == "zh-SG" ||  
      language == "zh"
    ) {
      language = "lang-zh-Hans";
    } else if (language == "zh-TW" || language == "zh-HK") {
      language = "lang-zh-Hant";
    } else {
      language = "lang-en";
    }
    config.headers["Accept-Language"] = language;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 统一响应处理
axiosServer.interceptors.response.use(
  function (response) {
    
    if (response.data.code == -1) {
      localStorage.removeItem("token");
      if (location.hash.split("?")[1] == undefined) {
        location.href = "/#/index";
      } else {
        location.href = "/#/index?" + location.hash.split("?")[1];
      }
      
      // Message({
      //   showClose: true,
      //   message: response.data.message,
      //   type: "error",
      //   center: true,
      //   duration: 0
      // });
    }
    return response;
  },
  function (error) {
    if (error.config.timeout) {
      const language = navigator.language;
      if (
        language == "zh-CN" ||
        language == "zh-TW" ||
        language == "zh-HK" ||
        language == "zh-MO" ||
        language == "zh-SG" ||
        language == "zh"
      ) {
        Message({
          showClose: true,
          message: "请求超时，请检查网络",
          type: "error",
          center: true
        });
      } else {
        Message({
          showClose: true,
          message: "Request timed out, please check network",
          type: "error",
          center: true
        });
      }
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = axiosServer;
  window.axios = axiosServer;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axiosServer;
      }
    },
    $axios: {
      get() {
        return axiosServer;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
