<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="invite-friend-layout">
      <div class="invite-friend-top">
        <!-- 导航条 -->
        <div class="if-page-header" style="padding: 25px 11px 0 11px;">
          <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <span class="top-button">
              <div class="top-button1" style="display: flex; align-items: center; flex-wrap: nowrap;">
                  <span @click="$router.push('/index')" class="main-0">
                      <img src="@/assets/images/index/indexIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_home')}}</div>
                  </span>
                  
                  <span @click="$router.push('/redeem')" class="main-0">
                      <img src="@/assets/images/index/exchangeIcon.png" style="width: 18px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_redeem')}}</div>
                  </span>
                  
                  <span @click="$router.push('/earn')" class="main-0">
                      <img src="@/assets/images/index/earnIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_earnMoney')}}</div>
                  </span>
                  
                  <span @click="$router.push('/inviteFriend')" class="main-0" style="background-color: rgba(80, 270, 119, 1);display: flex; flex-direction: column; align-items: center; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 90px; height: 49px;">
                      <img src="@/assets/images/index/inviteIcon1.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(0, 0, 0, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_invite')}}</div>
                  </span>
                  
                  <span @click="$router.push('/account')" style="display: flex; flex-direction: column; align-items: center;">
                      <img src="@/assets/images/index/accountIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_account')}}</div>
                  </span>
              </div>
          </span>
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 6px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
        </div>
        

        <div style="margin-top: 10px">
          <swiper :options="swiperOption1"  class="my-swiper">
            <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <div style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Gabriel...</div>
                  </div>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Alexan...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">40PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlott...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/linePayIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">LinePay</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Mia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">10TWD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Victor...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/trueMoneyIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-left: -5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">True Money</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Sophia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">50THB</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlo...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">150PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1"  />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Benja...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
                      
          </swiper>
        </div>

        <div style="display: flex; justify-content: center; align-items: center; margin-top: 30px; margin-bottom: 20px;">
          <div class="main-1">
            <div class="main-1-1">
              <div style="display: flex;">
                <img src="@/assets/images/index/dataIcon.png" style="width: 25px; height: auto; margin-right:8px" />
                <div style="color: rgba(255, 255, 255, 1); font-weight: 400; margin-right: 0px; font-size: 16px; margin-top: 9px">
                  {{$t('all.i_invitationData')}}
                </div>
              </div>
              <img @click="$router.push('/inviteLog')" src="@/assets/images/index/logIcon1.png" style="width: 25px; height: auto; " />
            </div>

            <div style="display: flex; margin-left: 25px; align-items: center;">
              <div style="margin-right: 17px;background: rgba(36, 72, 72, 1); border-radius: 20px; opacity: 1; width: 67px; height: 67px;display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                  <img src="@/assets/images/index/friendIcon.png" style="width: 36px; height: auto; " />
              </div>
              <div style="text-align: left; margin-top: 20px">
                <div style="color: rgba(255, 255, 255, 1); font-size: 29px">{{inviteCount}}</div>
                <div style="color: rgba(203, 204, 225, 1); font-size: 15px">{{$t('all.i_recommendToFriends')}}</div>
              </div>
            </div>
            <div style="display: flex; margin-left: 25px; align-items: center;">
              <div style="margin-right: 17px;background: rgba(36, 72, 72, 1); border-radius: 20px; opacity: 1; width: 67px; height: 67px;display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                  <img src="@/assets/common/cashIcon.png" style="width: 36px; height: auto; " />
              </div>
              <div style="text-align: left; margin-top: 20px">
                <div style="color: rgba(255, 255, 255, 1); font-size: 29px">{{reward}}</div>
                <div style="color: rgba(203, 204, 225, 1); font-size: 15px">{{$t('all.i_rewardByInvitation')}}</div>
              </div>
            </div>
          </div>
        </div>


        <div style="display: flex; justify-content: center; flex-direction: column; align-items: center; margin-bottom: 20px;">
          <div class="main-2">
            <div style="border: 2px solid rgba(49, 50, 79, 1);display: flex; justify-content: center; align-items: center; color:rgba(255,255,255,1);background: rgba(25, 26, 43, 1); width: 87%; height: 156px;border-radius: 12px;margin-top: -7px;">
                <div style="color: rgba(203, 204, 225, 1);font-weight: 400; width: 90%;line-height: 26px; opacity: 1;font-size: 15px; margin-top: -20px; text-align: left">
                  {{$t('all.i_joinFriendAndComplete')}} {{commission}}% {{$t('all.i_commission')}}({{$t('all.i_theMaximumLimit')}} {{maxReward}} {{$t('all.i_coins')}})。
                </div>
            </div>
            <div style="display: flex; flex-direction: column;  margin-top: 25px; margin-left: 5%; text-align: left; width: 92%">
              <div style="color: rgba(126, 124, 161, 1); opacity: 1;font-size: 15px;font-weight: 400; margin-bottom: 12px">{{$t('all.i_yourInvitationLink')}}</div>
              <a :href="inviteLink" target="_blank" style="color: rgba(255, 255, 255); font-size: 16px">
                {{ inviteLink }}
              </a>
            </div>
            <div @click="inviteLinkDrawer = true" style="margin-top: 23px;display: flex; justify-content: center; align-items: center; width: 280px; height: 47px; background: rgba(47, 48, 69, 1);border: 2px solid rgba(65, 66, 102, 1); border-radius: 12px;margin-top: 39px;">
              <img src="@/assets/images/index/shareIcon.png" style="width: 18px; height: auto;margin-top: 2px; margin-right: 10px" />
              <div  style="color: rgba(255, 255, 255, 1); opacity: 1;font-size: 14px;font-weight: 400;line-height: 49px">{{$t('all.i_inviteFriends')}}</div>
            </div>
          </div>
        </div>


        <div style="display: flex; justify-content: center; flex-direction: column; align-items: center; margin-bottom: 50px;">
          <div class="main-3">
            <div style="color: rgba(255, 255, 255, 1); opacity: 1;font-size: 21px;font-weight: 400; margin-top: 30px;">{{$t('all.i_instructions')}}</div>
            <div style="display: flex; margin-left: 18px; align-items: center;width: 92%; margin-bottom: 25px">
              <div style="margin-right: 14px;background: rgba(185, 255, 105, 1); border-radius: 35px; opacity: 1; width: 50px; height: 50px;display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                  1
              </div>
              <div style="text-align: left; margin-top: 20px; width: 76%">
                <div style="color: rgba(255, 255, 255, 1); font-size: 17px;margin-bottom: 5px">{{$t('all.i_step1')}}</div>
                <div style="color: rgba(168, 170, 185, 1); font-size: 13px">{{$t('all.i_step1Instructions')}}</div>
              </div>
            </div>
            <div style="display: flex; margin-left: 18px; align-items: center;width: 92%; margin-bottom: 20px">
              <div style="margin-right: 14px;background: rgba(251, 227, 56, 1); border-radius: 35px; opacity: 1; width: 50px; height: 50px;display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                  2
              </div>
              <div style="text-align: left; margin-top: 20px; width: 76%">
                <div style="color: rgba(255, 255, 255, 1); font-size: 17px;margin-bottom: 5px">{{$t('all.i_step2')}}</div>
                <div style="color: rgba(168, 170, 185, 1); font-size: 13px">{{$t('all.i_step2Instructions')}}</div>
              </div>
            </div>
            <div style="display: flex; margin-left: 18px; align-items: center;width: 92%;">
              <div style="margin-right: 14px;background: rgba(130, 108, 255, 1); border-radius: 35px; opacity: 1; width: 50px; height: 50px;display: flex; justify-content: center; align-items: center; margin-top: 20px;">
                  3
              </div>
              <div style="text-align: left; margin-top: 20px; width: 76%">
                <div style="color: rgba(255, 255, 255, 1); font-size: 17px;margin-bottom: 5px">{{$t('all.i_step3')}}</div>
                <div style="color: rgba(168, 170, 185, 1); font-size: 13px">{{$t('all.i_step3Instructions')}}</div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin: auto; display: flex; justify-content: center; align-items: center; flex-direction: column; background-color: rgba(29, 30, 48, 1); height: 138px; margin-top: 100px;">
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1.09px; margin-bottom: 12px; font-size: 15px;; font-size: 15px;">
              <span @click="openPolicy()" style="">《{{$t('index.i_privacyPolicy')}}》</span>
              <span @click="openService()"   style="">《{{$t('index.i_termsOfService')}}》</span>
          </div>
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1px; font-size: 12px">
            © 2020 - 2024 BIgbig Cash 2. All rights reserved.
          </div>
        </div>

        <div style="height: 10vh"></div>

      </div>


        

      <el-dialog :title="$t('all.i_earnRewards')" :visible.sync="claimRewards" width="76%" margin-top="18hv" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false" custom-class="custom-dialog">
          <div style="background-color: rgba(40, 41, 67, 1); border-radius: 15px; height: 65px; margin-top: 10px;" >
            <div style="position: relative; top: 20px;font-size: 26px;color: rgba(80, 214, 119, 1);white-space: pre-wrap;word-break: break-word; text-align: center;">+200<img src="@/assets/common/cashIcon1.png"  style="width: 18px; position: relative; top: 0px; left: 3px" /></div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="claimRewards=false, validation() " style="width: 85%; background-color: rgba(80, 214, 119, 1) !important;border-radius: 12px; margin-top: 35px">
              {{$t('inviteFriend.i_claimNow')}}
            </el-button>
          </div>
        </el-dialog>
          
        

        <el-dialog :title="$t('convert.i_tips')" :visible.sync="tipsDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
          <div style="margin-bottom: 6%;text-align: center;">
            <div style="font-size: 16px;color: #000;white-space: pre-wrap;word-break: break-word;">{{$t('inviteFriend.i_tipsDialogMsg2')}}</div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="tipsDialog = false;inviteLinkDrawer = true;" style="width: 60%;">
              {{$t('convert.i_ok')}}
            </el-button>
          </div>
        </el-dialog>

        <el-drawer size="40%" direction="btt" :with-header="false" :show-close="false" :visible.sync="inviteLinkDrawer">
          <div>
            <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
              <img :src="inviteInfo.appIcon" style="float: left;height: 50px;border-radius: 10px;" />
              <span style="float: left;margin-top: 5%;margin-left: 5%;font-weight: bold;color: black;">
                Bigbig Cash2
              </span>
            </div>
            <div style="background-color: rgba(223, 223, 227, 1);margin: 5%;padding: 3%;border-radius: 6px;">
              <span style="">
                <a :href="inviteLink" style="word-break: normal;overflow-wrap: break-word;">{{ inviteLink }}</a>
              </span>
            </div>
            <div>
              <el-button round type="primary" @click="copyVal(inviteLink)" style="width: 38%;background-color: rgba(0, 111, 255, 1);">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
          </div>
        </el-drawer>

        <!-- 初始任务状态Dialog -->
        <el-dialog :title="$t('convert.i_tips')" :visible.sync="initTaskStatusDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
          <div style="text-align: center;margin-bottom: 10%;">
            <div style="font-size: 16px;color: #000;word-break: normal;overflow-wrap: break-word;">{{ $t('all.i_pleaseInitTask') }}</div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="goTaskInfo()" style="width: 60%;">
              {{ $t('convert.i_ok') }}
            </el-button>
          </div>
        </el-dialog>

        <!-- 错误信息Dialog -->
        <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
          <div style="text-align: center;margin-bottom: 10%;">
            <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
          </div>
          <div style="text-align: center;">
            <el-button round type="primary" @click="go()" style="width: 60%;">
              {{ $t('convert.i_ok') }}
            </el-button>
          </div>
        </el-dialog>

      </div>
    </div>
  
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      inviteType: 1,
      userInfo: {
        uid: 0,
        avatar: "",
        cashNum: 0,
      },
      inputValue: "",
      failMessage: null,
      checkDialog: false,
      inviteInfo: {},
      inviteNum: {},
      inviteLinkDrawer: false,
      tipsDialog:false,
      inviteLink: "",
      inviteLogType: 1,
      inviteLogOne: [],
      busyOne: false,
      pageNumOne: 0,
      isLastOne: false,
      inviteLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      initTaskStatusDialog: false,
      reward: null,
      inviteCount: null,
      maxReward: null,
      commission: null,
      inviteCode: null,
      disable: false,
      claimRewards: false,
      displayValue: "",
      isVisible: "visible",
      swiperOption1: {
         slidesPerView: 'auto',
         spaceBetween: -70,
         loop: true,
         grid: {
            fill: 'column',
            rows: 1,
         },
         speed:9500,
         freeMode:true,

            autoplay: {

              delay:0,
              disableOnInteraction: false,
            },
        loopAdditionalSlides: 1
      },
    };
  },
  methods: {
    openService() {
      window.open(window.location.origin+"/BigBigSoftTermsOfService.html", "_blank");
    },

    openPolicy() {
      window.open(window.location.origin+"/BigBigSoftPrivacyPolicy.html", "_blank");
    },
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getInviteInfo() {
      this.isLoading = false;
      this.$axios({
        url: "/webCash/invite/info",
        method: "get",
        
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          // this.userInfo = result.data.data.userInfo;
          // this.$store.state.cashNum = result.data.data.userInfo.num;
          // this.inviteInfo = result.data.data.inviteInfo;
          // this.inviteNum = result.data.data.inviteNum;
          
          this.reward = result.data.data.reward;
          this.inviteCount = result.data.data.inviteCount;
          this.maxReward = result.data.data.maxReward;
          this.commission = result.data.data.commission;
          this.inviteCode = result.data.data.inviteCode;
          this.inviteLink = result.data.data.inviteLink;
        
          
          // this.inviteLink =
          //   window.location.origin +
          //   "/#/invite?tid=" +
          //   this.$route.query.tid +
          //   "&referrer=" +
          //   result.data.data.userInfo.inviteCode;
          // if (result.data.data.initTaskStatus == false) {
          //   this.initTaskStatusDialog = true;
          // }
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
          this.$router.push("/index");
        }
      });
    },

    checkInviteCode() {
      
      this.$axios({
        url: "/webCash/can/input-code",
        method: "get",
        
      }).then((result) => {
        this.isLoading = false;
        if (result.data != null) {
          
          this.disable = result.data.data.disable;
          
        } else if (result.data == null) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },

    validation() {
      
      this.$axios({
        url: "/webCash/validation",
        method: "get",
        params: {
          inviteCode: this.inputValue
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          
           this.displayValue = "邀请成功";
            var element = document.getElementById('toggleElement');
            element.style.visibility = 'visible';
            setTimeout(function() {
                element.style.visibility = "hidden";
            }, 2000); // 消失时间：2秒
            this.disable = true;
        } else if (result.data.code == 0) {
          
          if(result.data.message === "Invalid code"){
            this.displayValue = "邀请码无效";
            var element = document.getElementById('toggleElement');
            element.style.visibility = 'visible';
            setTimeout(function() {
                element.style.visibility = "hidden";
            }, 2000); // 消失时间：2秒
          }else if(result.data.message === "You have already helped, each user can only help once"){
            this.displayValue = "该已经被邀请";
            var element = document.getElementById('toggleElement');
            element.style.visibility = 'visible';
            setTimeout(function() {
                element.style.visibility = "hidden";
            }, 2000); // 消失时间：2秒
          }
        }
          
      });
    },
    goApp() {
      window.location.href = this.userInfo.appScheme;
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goTaskInfo() {
      this.$router.push({
        path: "/taskInfo",
        query: {
          tid: this.$route.query.tid,
          referrer: this.$route.query.referrer
        }
      });
      this.initTaskStatusDialog = false;
    },
  },
  created() {
    this.getInviteInfo();
    this.checkInviteCode();
  }
};
</script>

<style>
#invite-friend-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}

#invite-friend-layout .invite-friend-top {
  padding-bottom: 2%;
}

#invite-friend-layout .if-page-header {
  overflow: hidden;
  background-color: rgba(28, 29, 47, 1)
}

#invite-friend-layout .el-page-header__left::after {
  display: none;
}

#invite-friend-layout .i-gift-code-btn,
#invite-friend-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#invite-friend-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

#invite-friend-layout .inviteLogAct {
  color: rgba(32, 35, 42, 1) !important;
}

#invite-friend-layout .leaderboardAct {
  position: relative; /* 为伪元素定位做准备 */
  color: white !important; /* 保持文字颜色为白色 */
  border-radius: 30px; /* 保持圆角效果 */
}

#invite-friend-layout .leaderboardAct::after {
  content: ''; /* 伪元素内容为空 */
  position: absolute; /* 绝对定位 */
  left: 30%; /* 调整下划线起始位置 */
  bottom: -5px; /* 调整下划线与文字的距离 */
  width: 42%; /* 调整下划线的长度 */
  height: 3px; /* 下划线的粗细 */
  background-color: white; /* 下划线颜色 */
}

#invite-friend-layout .custom-input .el-input__inner {
  background-color: rgba(13, 98, 75, 1);
  width: 232px;
  border-radius: 15px;
  color: rgba(255, 178, 0, 1);
  text-align: center;
}

#invite-friend-layout .custom-input .el-input__inner::placeholder {
  color: rgba(53, 164, 134, 1);        /* 修改颜色 */
  font-size: 14px;    /* 修改字体大小 */
  text-align: center;
}

#invite-friend-layout .custom-dialog .el-dialog__body {
  background-color:  rgba(47, 49, 78, 1) !important; /* 调整对话框主体的背景颜色 */
  height: 150px; /* 设置高度 */
  overflow: auto; 
}
  
#invite-friend-layout .custom-dialog .el-dialog__header {
  background-color: rgba(47, 49, 78, 1) !important; /* 调整对话框头部的背景颜色 */
}

#invite-friend-layout .custom-dialog .el-dialog__title {
  color:rgba(255,255,255,1) !important;
}

#invite-friend-layout .custom-dialog .el-dialog {
  top: 50px; /* 从顶部开始的距离 */
  left: 50px; /* 从左边开始的距离 */
  margin: 0; /* 清除默认的 margin */
  position: relative;; /* 或使用 fixed，如果需要固定位置 */
}

#invite-friend-layout  .swiper{
  width:230px !important;
  
}

 @media screen and (min-width: 1280px){
  #invite-friend-layout .main-1-1{
    display: flex; 
    justify-content: space-between; 
    margin-left: 30px; 
    align-items: center;
    margin-top: 20px; 
    width: 95%
  }

  #invite-friend-layout .main-1{
    display: flex; 
    flex-direction: column; 
    background: rgba(34, 35, 56, 1); 
    width: 1200px; 
    height: 270px; 
    border-radius: 12px;
  }

  #invite-friend-layout .main-2{
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    background: rgba(34, 35, 56, 1); 
    width: 1200px; 
    height: 410px; 
    border-radius: 12px;
  }

  #invite-friend-layout .main-3{
    border-radius: 12px;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    background: linear-gradient(300.35deg, rgba(50, 39, 81, 1) 0%, rgba(50, 39, 81, 1) 1%, rgba(29, 30, 48, 1) 48.75%, rgba(76, 106, 70, 1) 100%); 
    width: 1200px; 
    height: 380px;
  }

  #invite-friend-layout .my-swiper {
    width: 1200px; /*设置Swiper的宽度*/
    overflow: hidden; /* 隐藏溢出的内容 */
    margin: auto; /* 水平居中 */
  }
  
 }

 @media screen and (max-width: 1279px){
  #invite-friend-layout .main-1-1{
    display: flex; 
    justify-content: space-between; 
    margin-left: 30px; 
    align-items: center;
    margin-top: 20px; 
    width: 85%
  }
  #invite-friend-layout .main-1{
    display: flex; 
    flex-direction: column; 
    background: rgba(34, 35, 56, 1); 
    width: 92%; 
    height: 270px; 
    border-radius: 12px;
  }

  #invite-friend-layout .main-2{
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    background: rgba(34, 35, 56, 1); 
    width: 92%; 
    height: 410px; 
    border-radius: 12px;
  }

  #invite-friend-layout .main-3{
    border-radius: 12px;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    background: linear-gradient(300.35deg, rgba(50, 39, 81, 1) 0%, rgba(50, 39, 81, 1) 1%, rgba(29, 30, 48, 1) 48.75%, rgba(76, 106, 70, 1) 100%); 
    width: 92%; 
    height: 380px;
  }
 }

 @media screen and (min-width: 768px) and (max-width: 1280px) {
  #invite-friend-layout .main-1-1{
    display: flex; 
    justify-content: space-between; 
    margin-left: 30px; 
    align-items: center;
    margin-top: 20px; 
    width: 93%
  }
  

 }



</style>
