<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-info-layout">
      <div class="ci-page-header" style="padding: 25px 11px 0 11px;">
        <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-1">
              <div @click="$router.push('/redeem')" style="display: flex; justify-content: center; align-items: center; background: rgba(40, 37, 56, 1); width: 80px; height: 33px; ">
                  <img src="@/assets/images/index/arrowIcon2.png" style="max-width: 18px; height: auto; margin-right: 6px" />
                  <div style="color: rgba(164, 159, 191, 1); font-weight: 700; letter-spacing: 1.46px; font-size: 15px">{{$t('all.i_back')}}</div>
              </div>
        </div>
      </div>

      <!-- <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p> -->

      <div class="main-2">
        <div style="font-weight: 400; color: rgba(255, 255, 255, 1); line-height: 60px; font-size: 20px; margin-top: 5px;opacity: 1;">{{$t('all.i_tips')}}</div>
        <div style="width: 91%;color: rgba(168, 167, 198, 1); margin-top: -10px;margin-bottom: 25px;white-space: pre-wrap;word-break: break-word;line-height: 26px;text-align: left;" v-html="giftCard.remarks"></div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-3">
          <el-form ref="bindGiftCard" :model="bindGiftCard" :rules="formRules" style="text-align: center;">
            <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName" class="el-form">
              <span v-if="form.keyName == 'account'" @click="updateAccount()" style="float: right;color: rgba(0, 183, 0, 1);text-decoration: underline;">
                {{$t('convert.i_editAccount')}}
              </span>
              <el-input v-if="form.keyName == 'account'" type="text" v-model="bindGiftCard.account" :placeholder="form.placeholder"  />
              <el-input v-if="form.keyName == 'accountName'" type="text" v-model="bindGiftCard.accountName" :placeholder="form.placeholder" />
              <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="bindGiftCard.extraOne" :placeholder="form.placeholder" />
              <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="bindGiftCard.extraTwo" :placeholder="form.placeholder" />
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-4">
          <div style="overflow: hidden;">
            <span style="float: left;line-height: 28px; color: rgba(255, 255, 255, 1);">{{ $t('convert.i_select') }}</span>
            <span style="float: right;">
              <span style="vertical-align: middle; color: rgba(255, 255, 255, 1);">{{ $t('convert.i_currency') }} </span>
              <el-select v-model="currencyId" size="mini" @change="getItems()" filterable style="width: 100px;">
                <el-option v-for="currencies in giftCard.currencies" :key="currencies.id" :label="currencies.currencyEn" :value="currencies.currencyId" />
              </el-select>
            </span>
          </div>

          <el-radio-group v-model="convertRadio" style="display: block !important;margin-top: 7px;">
            <div v-for="item in items" :key="item.itemId" style="margin-bottom: 10px; ">
              <el-radio-button :label="item.itemId" style="display: block !important; ">
                <div style="overflow: hidden;margin-top: 2px; ">
                  <span class="main-4-1">
                    <span style="font-size: 24px;vertical-align: middle; color: rgba(255, 255, 255, 1); margin-right: 5px"> {{ item.gold }}</span>
                    <img src="@/assets/common/cashIcon.png" style="width: 20px;vertical-align: middle;" />
                  </span>
                  <span class="main-4-2">{{ item.goldValue }}</span>
                </div>
              </el-radio-button>
            </div>
          </el-radio-group>
          

          <div style="padding-bottom: 50%;">
            <el-button type="primary" @click="toConvert('bindGiftCard')" class="main-5-1">
              {{ $t('convert.i_redeemNow') }}
            </el-button>
          </div>
        </div>
      </div>

      <el-dialog :title="$t('convert.i_modifyReceivingAccount')" :visible.sync="updateDialog" width="320px" center>
        <div v-if="updateActive == 1">
          <el-form ref="updateForm" :model="updateForm" :rules="formRules" style="text-align: center;">
            <el-form-item v-for="(form, index) in giftCard.forms" :key="index" :label="form.label" :prop="form.keyName">
              <el-input v-if="form.keyName == 'account'" type="text" v-model="updateForm.account" :placeholder="form.placeholder" />
              <el-input v-else-if="form.keyName == 'accountName'" type="text" v-model="updateForm.accountName" :placeholder="form.placeholder" />
              <el-input v-else-if="form.keyName == 'extraOne'" type="text" v-model="updateForm.extraOne" :placeholder="form.placeholder" />
              <el-input v-else-if="form.keyName == 'extraTwo'" type="text" v-model="updateForm.extraTwo" :placeholder="form.placeholder" />
            </el-form-item>
            <div style="padding-bottom: 8%;text-align: left;font-size: 11px;color: rgba(161, 134, 107, 1);">*{{ $t('convert.i_bindN') }}</div>
          </el-form>

          <div style="text-align: center;">
            <el-button type="primary" @click="onUpdateCheck('updateForm')" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
              {{ $t('convert.i_changeNow') }}
            </el-button>
            <div style="margin-top: 4%;">
              <span style="color: rgba(144, 144, 144, 1);" @click="updateDialog = false">{{ $t('common.i_cancel') }}</span>
            </div>
          </div>
        </div>

        <div v-if="updateActive == 2">
          <p>{{ $t('convert.i_changeT') }}</p>

          <div v-for="(form, index) in giftCard.forms" :key="index">
            <label>{{ form.label }}：</label>
            <div style="color: rgba(0, 183, 0, 1);">
              <p v-if="form.keyName == 'account'">{{ updateForm.account }}</p>
              <p v-if="form.keyName == 'accountName'">{{ updateForm.accountName }}</p>
              <p v-if="form.keyName == 'extraOne'">{{ updateForm.extraOne }}</p>
              <p v-if="form.keyName == 'extraTwo'">{{ updateForm.extraTwo }}</p>
            </div>
          </div>

          <div style="text-align: center;">
            <el-button type="primary" @click="toUpdateGiftCard()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">{{ $t('convert.i_confirm') }}
            </el-button>
            <div style="margin-top: 4%;">
              <span style="color: rgba(144, 144, 144, 1);" @click="updateDialog = false; active = 1;">{{ $t('common.i_cancel') }}</span>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog :title="$t('convert.i_convert') + ' - ' + convertTitle" :visible.sync="convertMsgDialog" width="320px" center :closeOnClickModal="convertCloseOnClickModal" :closeOnPressEscape="convertCloseOnPressEscape" :showClose="convertShowClose">
        <div style="text-align: center;">
          <div style="font-size: 16px;color: #000;">{{ convertMsg }}</div>
          <div style="margin-top: 10%;">
            <el-button type="primary" @click="convertOk()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
              {{ $t('convert.i_ok') }}
            </el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="convertDialog" width="320px"  center >
        <div style="margin-bottom: 6%;text-align: center;">
          <img src="@/assets/images/convert002.png" style="margin-bottom: 6%;width: 70px; " />
          <div style="font-size: 16px;color: rgba(225, 225, 255, 1);white-space: pre-wrap;word-break: break-word;">{{ $t('convert.i_convertMsg') }}</div>
        </div>
        <div style="text-align: center;">
          <el-button type="primary" @click="$router.push('/convertLog')" style="background-color: rgba(80, 214, 119, 1);width: 60%;border-radius: 30px;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>

      <el-dialog :title="$t('convert.i_tips')" :visible.sync="convertBindDialog" width="320px" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
        <div style="text-align: center;margin-bottom: 6%;">
          <div style="font-size: 16px;color: #000;">{{ $t('convert.i_activateMsg') }}</div>
        </div>
        <div style="text-align: center;">
          <el-button type="primary" @click="$router.push('/moneySteps')" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>

      <el-dialog :title="$t('convert.i_checkConvertInfo')" :visible.sync="checkConvertDialog" width="320px" center>
        <div v-for="item in items" :key="item.itemId">
          <div v-if="item.itemId == convertRadio">
            <span style="vertical-align: middle;">
              <span style="font-size: 22px;vertical-align: middle;"> {{ item.gold }}</span>
              <img src="@/assets/common/cash1Icon.png" style="margin-left: 6px;width: 22px;vertical-align: middle;" />
            </span>
            <img src="@/assets/images/convert/convertB.png" style="margin: auto 18px;width: 18px;vertical-align: middle;" />
            <span style="font-size: 22px;vertical-align: middle;">{{ item.goldValue }}</span>
          </div>
        </div>

        <div v-for="(form, index) in giftCard.forms" :key="index" style="margin-top: 4%;">
          <label>{{ form.label }}：</label>
          <div style="color: rgba(0, 183, 0, 1);">
            <p v-if="form.keyName == 'account'">{{ bindGiftCard.account }}</p>
            <p v-if="form.keyName == 'accountName'">{{ bindGiftCard.accountName }}</p>
            <p v-if="form.keyName == 'extraOne'">{{ bindGiftCard.extraOne }}</p>
            <p v-if="form.keyName == 'extraTwo'">{{ bindGiftCard.extraTwo }}</p>
          </div>
        </div>

        <div style="margin-top: 12%;text-align: center;">
          <el-button type="primary" @click="onConvert()" style="background-color: rgba(60, 88, 154, 1);width: 60%;border-radius: 30px;">
            {{ $t('convert.i_redeemNow') }}
          </el-button>
          <div style="margin-top: 4%;">
            <span style="color: rgba(144, 144, 144, 1);" @click="checkConvertDialog = false; active = 1;">{{ $t('common.i_cancel') }}</span>
          </div>
        </div>
      </el-dialog>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ convertInfoParam.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    const checkWhetherSpace = (rule, value, callback) => {
      this.giftCard.forms.forEach((form) => {
        if (form.keyName == rule.field) {
          if (form.regexps != null && !new RegExp(form.regexps).test(value)) {
            callback(new Error(form.regexpsTips));
          } else {
            callback();
          }
        }
      });
    };
    return {
      isLoading: false,
      checkDialog: false,
      convertTitle: "",
      convertMsg: "",
      convertShowClose: true,
      convertCloseOnPressEscape: true,
      convertCloseOnClickModal: true,
      convertBindDialog: false,
      convertStatus: 3,
      updateDialog: false,
      convertDialog: false,
      convertMsgDialog: false,
      checkConvertDialog: false,
      updateActive: 1,
      currencyId: "",
      convertRadio: "",
      giftCard: {},
      items: [],
      bindGiftCard: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      convertInfo: {
        itemId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      updateForm: {
        cardId: "",
        account: "",
        accountName: "",
        extraOne: "",
        extraTwo: ""
      },
      formRules: {
        account: [
          {
            required: true,
            message: this.$t("convert.i_accountN"),
            trigger: ["blur", "change"]
          },
          {
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        accountName: [
          {
            required: true,
            message: this.$t("convert.i_nameN"),
            trigger: ["blur", "change"]
          },
          {
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        extraOne: [
          {
            required: true,
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ],
        extraTwo: [
          {
            required: true,
            validator: checkWhetherSpace,
            trigger: ["blur", "change"]
          }
        ]
      },
      regexps: "",
      regexpsTips: "",
      convertInfoParam: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.convertInfoParam.check == "login") {
        window.location.href = this.convertInfoParam.appScheme + "login";
      } else {
        window.location.href = this.convertInfoParam.appScheme;
      }
    },
    onUpdateCheck(formName) {
      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {  
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  this.$refs[formName].validateField(
                    ["extraOne"],
                    (validErr) => {
                      if (!validErr) {
                        if (this.giftCard.forms.length >= 4) {
                          this.$refs[formName].validateField(
                            ["extraTwo"],
                            (validErr) => {
                              if (!validErr) {
                                this.updateActive = 2;
                              }
                            }
                          );
                        } else {
                          if (!validErr) {
                            this.updateActive = 2;
                          }
                        }
                      }
                    }
                  );
                } else {
                  if (!validErr) {
                    this.updateActive = 2;
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.updateActive = 2;
            }
          }
        }
      });
    },
    convertOk() {
      if (this.convertStatus == 1) {
        this.$router.go(-1);
      } else if (this.convertStatus == 3) {
        this.convertMsgDialog = false;
      }
    },
    updateAccount() {
      this.updateActive = 1;
      this.updateDialog = true;
    },
    getItems() {
      this.giftCard.currencies.forEach((currency) => {
        if (currency.currencyId == this.currencyId) {
          this.items = currency.items;
        }
      });
      this.convertRadio = this.items[0].itemId;
    },
    queryBindCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/queryBind?cardId=" + this.giftCard.cardId,
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        //console.log(result.data);
        if (result.data.code == 1) {
          if (result.data.data.isActivation == false) {
            this.convertBindDialog = true;
          } else {
            if (
              result.data.data.updateLog == null ||
              result.data.data.accountBind == null
            ) {
              if (result.data.data.accountBind == null) {
                //this.bindDialog = true;
              } else {
                this.bindGiftCard.account =
                  result.data.data.accountBind.bindAccount;
                this.bindGiftCard.accountName =
                  result.data.data.accountBind.accountName;
                this.bindGiftCard.extraOne =
                  result.data.data.accountBind.extraOne;
                this.bindGiftCard.extraTwo =
                  result.data.data.accountBind.extraTwo;
                this.updateForm.account = this.bindGiftCard.account;
                this.updateForm.accountName = this.bindGiftCard.accountName;
                this.updateForm.extraOne = this.bindGiftCard.extraOne;
                this.updateForm.extraTwo = this.bindGiftCard.extraTwo;
              }
            } else {
              this.bindGiftCard.account =
                result.data.data.accountBind.bindAccount;
              this.bindGiftCard.accountName =
                result.data.data.accountBind.accountName;
              this.bindGiftCard.extraOne =
                result.data.data.accountBind.extraOne;
              this.bindGiftCard.extraTwo =
                result.data.data.accountBind.extraTwo;
              this.updateForm.account = this.bindGiftCard.account;
              this.updateForm.accountName = this.bindGiftCard.accountName;
              this.updateForm.extraOne = this.bindGiftCard.extraOne;
              this.updateForm.extraTwo = this.bindGiftCard.extraTwo;
              this.convertStatus = result.data.data.updateLog.status;
              if (result.data.data.updateLog.status == 1) {
                this.convertShowClose = false;
                this.convertCloseOnPressEscape = false;
                this.convertCloseOnClickModal = false;
                this.convertTitle = this.$t("convert.i_info_ing");
                this.convertMsg = result.data.data.updateLog.msg;
                this.convertMsgDialog = true;
              } else if (result.data.data.updateLog.status == 3) {
                this.convertTitle = this.$t("convert.i_infoN");
                this.convertMsg = result.data.data.updateLog.msg;
                this.convertMsgDialog = true;
              }
            }
          }
        } else if (result.data.code == -2) {
          this.convertInfoParam.check = result.data.data.check;
          this.convertInfoParam.failMessage = result.data.message;
          this.convertInfoParam.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    toUpdateGiftCard() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/updateBind",
        method: "post",
        params: this.updateForm
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.$router.push("/redeem");
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
      this.updateDialog = false;
    },
    goConvert() {
      this.convertInfo.itemId = this.convertRadio;
      this.convertInfo.account = this.bindGiftCard.account;
      this.convertInfo.accountName = this.bindGiftCard.accountName;
      this.convertInfo.extraOne = this.bindGiftCard.extraOne;
      this.convertInfo.extraTwo = this.bindGiftCard.extraTwo;
      this.isLoading = true;
      this.$axios({
        url: "/webCash/exchange",
        method: "post",
        params: this.convertInfo
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.convertDialog = true;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    onConvert() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/queryBind?cardId=" + this.giftCard.cardId,
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        //console.log(result.data);
        if (result.data.code == 1) {
          if (result.data.data.isActivation == false) {
            this.convertBindDialog = true;
          } else {
            if (
              result.data.data.updateLog == null ||
              result.data.data.accountBind == null
            ) {
              if (result.data.data.accountBind == null) {
                this.isLoading = true;
                this.$axios({
                  url: "/webCash/bind",
                  method: "post",
                  params: this.bindGiftCard
                }).then((result) => {
                  this.isLoading = false;
                  if (result.data.code == 1) {
                    this.goConvert();
                  } else {
                    this.$message({
                      showClose: true,
                      message: result.data.message,
                      type: "error",
                      center: true
                    });
                  }
                });
              } else {
                this.goConvert();
              }
            } else {
              this.goConvert();
            }
          }
        } else if (result.data.code == -2) {
          this.convertInfoParam.check = result.data.data.check;
          this.convertInfoParam.failMessage = result.data.message;
          this.convertInfoParam.appScheme = result.data.data.appScheme;
          this.checkDialog = true;
        }
      });
    },
    toConvert(formName) {
      this.$refs[formName].validateField(["account"], (validErr) => {
        if (!validErr) {
          if (this.giftCard.forms.length >= 2) {
            console.log(111)
            this.$refs[formName].validateField(["accountName"], (validErr) => {
              if (!validErr) {
                if (this.giftCard.forms.length >= 3) {
                  console.log(222)
                  this.$refs[formName].validateField(
                    ["extraOne"],
                    (validErr) => {
                      if (!validErr) {
                        if (this.giftCard.forms.length >= 4) {
                          console.log(333)
                          this.$refs[formName].validateField(
                            ["extraTwo"],
                            (validErr) => {
                              if (!validErr) {
                                this.checkConvertDialog = true;
                              }
                            }
                          );
                        } else {
                          if (!validErr) {
                            this.checkConvertDialog = true;
                          }
                        }
                      }
                    }
                  );
                } else {
                  if (!validErr) {
                    this.checkConvertDialog = true;
                  }
                }
              }
            });
          } else {
            if (!validErr) {
              this.checkConvertDialog = true;
            }
          }
        }
      });
    }
  },
  created() {
    const giftCards = JSON.parse(sessionStorage.getItem("giftCardList"));
    this.$store.state.goldNum = JSON.parse(sessionStorage.getItem("goldNum"));
    giftCards.forEach((giftCard) => {
      if (giftCard.cardId == this.id) {
        this.giftCard = giftCard;
      }
    });
    this.currencyId = this.giftCard.currencies[0].currencyId;
    this.items = this.giftCard.currencies[0].items;
    this.convertRadio = this.giftCard.currencies[0].items[0].itemId;
    this.bindGiftCard.cardId = this.giftCard.cardId;
    this.updateForm.cardId = this.giftCard.cardId;
    this.regexps = this.giftCard.forms[0].regexps;
    this.regexpsTips = this.giftCard.forms[0].regexpsTips;
    this.queryBindCard();
  }
};
</script>

<style>
#convert-info-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}


#convert-info-layout .el-radio-button__inner {
  background-color: rgba(40, 41, 64, 1) !important;
  color: black !important;
  background-image: url("@/assets/images/convert/convertB.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}

#convert-info-layout .is-active .el-radio-button__inner {
  background-color: rgba(95, 197, 167, 1)!important;
  color: white !important;
  background-image: url("@/assets/images/convert/convertW.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}

#convert-info-layout .ci-page-header {
  overflow: hidden;
  background-color: rgba(28, 29, 47, 1)
}

#convert-info-layout .el-radio-button__inner {
  display: block !important;
}

#convert-info-layout .el-form-item__label {
  color: white !important; /* 修改这里的颜色 */
}

 #convert-info-layout .el-input__inner {
  background-color: rgba(40, 41, 64, 1) !important; /* 修改这里的颜色 */
  color: white !important; 
}

#convert-info-layout .el-dialog__header{
  background-color: rgba(28, 29, 47, 1) !important;
}

#convert-info-layout .el-dialog__body{
  background-color: rgba(28, 29, 47, 1) !important;
}

#convert-info-layout .el-dialog__title{
  color: white !important; 
}


@media screen and (max-width: 1500px){
  #convert-info-layout .main-1{
  display: flex; 
  justify-content: left; 
  align-items: center; 
  margin-top: 22px; 
  /* margin-left: -66%; */
  margin-bottom: 20px;
  width: 92%
 }

 #convert-info-layout .main-2{
  display: flex; 
  align-items: center; 
  flex-direction: column; 
  background: rgba(34, 35, 55, 1); 
  width: 92%;  
  margin: auto; 
  border-radius: 12px;
  margin-bottom: 26px;
  }

  #convert-info-layout .main-3{
    width: 92%;
  }
  #convert-info-layout .main-4{
    width: 92%;
    margin-top: 40px
  }
  #convert-info-layout .main-4-1{
    float: left;
  }
  #convert-info-layout .main-4-2{
    float: right;
    font-size: 24px; 
    color: rgba(255, 255, 255, 1);
  }
  #convert-info-layout .main-5-1{
    color: rgba(39, 32, 6, 1);
    background-color: rgba(80, 214, 119, 1);
    border-color: rgba(80, 214, 119, 1);
    width: 58%;
    margin-top: 50px;
    border-radius: 12px;
  }
}



@media screen and (min-width: 1281px) {
  #convert-info-layout .main-1{
  display: flex; 
  justify-content: left; 
  align-items: center; 
  margin-top: 35px; 
  margin-bottom: 10px;
  width: 1200px;
  /* margin-left: -1060px */
 }

 #convert-info-layout .main-2{
  display: flex; 
  align-items: center; 
  flex-direction: column; 
  background: rgba(34, 35, 55, 1); 
  width: 1200px;  
  margin: auto; 
  border-radius: 12px;
  /* height: 200px; */
  margin-bottom: 30px;
  }
  #convert-info-layout .main-3{
    /* margin-left: 20%; */
    /* margin-right: 20%; */

  width: 1200px;
    
  }
  #convert-info-layout .main-4{
    width: 1200px;
    margin-top: 40px
  }
  #convert-info-layout .main-5-1{
    color: rgba(39, 32, 6, 1);
    background: linear-gradient(180deg, rgba(212, 184, 51, 1) 0%, rgba(212, 184, 51, 1) 1.37%, rgba(246, 232, 9, 1) 100%);
    border-color: rgba(212, 184, 51, 1);
    width: 18%;
    margin-top: 50px;
    border-radius: 12px;
  }
  #convert-info-layout .main-4-1{
    margin-right: 100px;
  }
  #convert-info-layout .main-4-2{
    
    font-size: 24px; 
    color: rgba(255, 255, 255, 1);
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
 
  #convert-info-layout .main-5-1{
    color: rgba(39, 32, 6, 1);
    background-color: rgba(80, 214, 119, 1);
    border-color: rgba(80, 214, 119, 1);
    width: 28%;
    margin-top: 50px;
    border-radius: 12px;
  }
  #convert-info-layout .main-4-1{
    float: left;
  }
  #convert-info-layout .main-4-2{
    float: right;
    font-size: 24px; 
    color: rgba(255, 255, 255, 1);
  }
}

</style>
