<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="index-layout" style="background-color: rgba(1, 1, 1, 1);">
      <div class="index-top">
        <!-- 导航条 -->
        <div class="i-page-header" style="padding: 25px 11px 0 11px;">
          <div>
              <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1"/>
              <span class="top-button">
                  <div class="top-button1" style="display: flex; align-items: center; flex-wrap: nowrap;">
                      <span @click="$router.push('/index')" class="main-0" style="background-color: rgba(80, 270, 119, 1);display: flex; flex-direction: column; align-items: center; justify-content: center; width: 90px; height: 49px;">
                          <img src="@/assets/images/index/indexIcon1.png" style="width: 20px; vertical-align: middle; " />

                          <div style="color: rgba(0, 0, 0, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_home')}}</div>
                      </span>
                      
                      <span @click="$router.push('/redeem')" class="main-0">
                          <img src="@/assets/images/index/exchangeIcon.png" style="width: 18px; vertical-align: middle;" />
                          <div style="color: rgba(199, 200, 218, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_redeem')}}</div>
                      </span>
                      
                      <span @click="$router.push('/earn')" class="main-0">
                          <img src="@/assets/images/index/earnIcon.png" style="width: 20px; vertical-align: middle;" />
                          <div style="color: rgba(199, 200, 218, 1); font-size: 12px; margin-top: 5px; width: 45px">{{$t('index.i_earnMoney')}}</div>
                      </span>
                      
                      <span @click="$router.push('/inviteFriend')" class="main-0">
                          <img src="@/assets/images/index/inviteIcon.png" style="width: 20px; vertical-align: middle;" />
                          <div style="color: rgba(199, 200, 218, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_invite')}}</div>
                      </span>
                      
                      <span @click="$router.push('/account')" style="display: flex; flex-direction: column; align-items: center;">
                          <img src="@/assets/images/index/accountIcon.png" style="width: 20px; vertical-align: middle;" />
                          <div style="color: rgba(199, 200, 218, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_account')}}</div>
                      </span>
                  </div>
              </span>
              <div class="top-2">
                <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 6px;">
                  <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
                  <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                    {{ $store.state.cashNum }}
                  </span>
                </div>
                <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
                  <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
                  <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
                </div> -->
              </div>
          </div>
        </div>


        <div style="margin-top: 12px">
          <swiper :options="swiperOption1"  class="my-swiper">
            <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <div style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Gabriel...</div>
                  </div>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Alexan...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">40PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlott...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/linePayIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">LinePay</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Mia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">10TWD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Victor...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/trueMoneyIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-left: -5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">True Money</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Sophia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">50THB</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlo...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">150PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1"  />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Benja...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
                      
          </swiper>
        </div>



        
        

        <div class="main-1">
          <div class="main-1-1">
              <div style="margin-top: 10px;letter-spacing: 1.03px;line-height: 45px;color:rgba(255, 255, 255, 1); font-size: 13px;margin-bottom: 9px;font-weight: 700;">{{$t('index.i_joinbbc2')}}</div>
              <div style="color:rgba(0, 214, 118, 1);font-size: 20px; letter-spacing: 0.03px;margin-bottom: 47px;font-weight: 700;">{{$t('index.i_earnOver')}}</div>
              <div v-if="userInfo.inviteCode == ''" @click="loginDialog = true"  style="background-color: rgba(81, 215, 120, 1); width: 256px; height: 47px; border-radius: 10px; margin-bottom: 37px">
                <img src="@/assets/images/index/googleIcon.png" style="max-width: 80%; height: auto;width: 22px; position: relative; top: 12px; left: -12px">
                <span style="color: rgba(19, 21, 34, 1); font-size: 14px; letter-spacing: 0.8px;position: relative; left: 3px;top:6px;font-weight: 700;">Google</span>
              </div>
              <div v-else @click="$router.push('/earn')"  style="justify-content: center;display: flex; align-items: center;background-color: rgba(81, 215, 120, 1); width: 256px; height: 47px; border-radius: 10px; margin-bottom: 37px">
                <!-- <img src="@/assets/images/index/googleIcon.png" style="max-width: 80%; height: auto;width: 22px; position: relative; top: 12px; left: -12px"> -->
                <span style="color: rgba(19, 21, 34, 1); font-size: 14px; letter-spacing: 0.8px;font-weight: 700;">{{$t('index.i_startEarningMoney')}}</span>
              </div>
              <div style="font-weight: 700; margin-bottom: 24px">
                <div style="font-weight: 700; color: rgba(168, 167, 198, 1); margin-bottom: 5px; font-size: 15px">{{$t('index.i_goodReviewsRate')}}</div>
                <img src="@/assets/images/index/starIcon.png" style="max-width: 80%; height: auto;">
              </div>

              <div class="main-1-1-1">
                <div style="display: flex; align-items: center; width: 90%; margin-top: 0px; margin-left: 25px; font-size: 15px;">
                  <img src="@/assets/images/index/yesIcon.png" style="max-width: 19px; height: auto; width: 22px; margin-right: 10px;">
                  <span>{{$t('index.i_earnOver')}}</span>
                </div>
                <div style="display: flex; align-items: center; width: 90%; margin-top: 11px; margin-left: 22px; font-size: 15px;">
                  <img src="@/assets/images/index/yesIcon.png" style="max-width: 19px; height: auto; width: 22px; margin-right: 10px;">
                  <span>{{$t('index.i_getCash')}}</span>
                </div>
                <div style="display: flex; align-items: center; width: 90%; margin-top: 11px; margin-left: 20px; font-size: 15px;">
                  <img src="@/assets/images/index/yesIcon.png" style="max-width: 19px; height: auto; width: 22px; margin-right: 10px;">
                  <span>{{$t('index.i_lotsOfCashTask')}}</span>
                </div>
              </div>    
                    
          </div>
        </div>


        <div style="display: flex; justify-content: center;flex-direction: column;">
          <div style="margin-top: 8px;letter-spacing: 2.03px;line-height: 45px;color:rgba(255, 255, 255, 1); font-size: 18px;margin-bottom: 9px;font-weight: 700;">{{$t('index.i_manyRedemption')}}</div>
        </div>
        
        <swiper :options="swiperOption2"  class="my-swiper1">
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/gcashIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/payeerIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/trueMoneyIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/pixIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/chinahostIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/imepayIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/payPalIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/irtmIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
               <img src="@/assets/images/index/danaIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
            <swiper-slide style="flex: 0 0 auto; width:100px;" class="swiper">
                <img src="@/assets/images/index/linePayIcon.png" style="max-width: 39%; height: auto; " />
            </swiper-slide>
        </swiper>


        <div style="display: flex; flex-direction: column; align-items: center; margin-top:45px">

          <div style="display: flex; justify-content: center;flex-direction: column;background-color: rgba(17, 19, 31, 1); border-radius: 14.93px;border: 3px solid rgba(44, 44, 68, 1); width: 260px; height:80px">
            <span style="color: rgba(81, 215, 120, 1);font-weight: 700;letter-spacing: 1.95px;text-align: center;font-size: 25px;">20+</span>
            <span style="color: rgba(168, 167, 198, 1);font-weight: 700;letter-spacing: 1.95px;text-align: center;font-size: 15px;">{{$t('index.i_cashRedemption')}}</span>
          </div>
        </div>

        <div class="main-2">
          <div class="main-2-1">
            <div class="main-2-1-1" >
              <img src="@/assets/images/index/playIcon.png" style="width: 65px; height: auto; " />
            </div>
            <div style="color: rgba(255, 255, 255 ,1);font-size: 18px;font-weight: 700;letter-spacing: 1.5px; margin-bottom: 60px">{{$t('index.i_playMobileGames')}}</div>
            <div class="main-2-1-2">
              {{$t('index.i_discoverNewMobile')}}
            </div>
            <div @click="$router.push('/earn')">
              <span style="color: rgba(81, 215, 120, 1);font-weight: 400;letter-spacing: 1.17px;font-size: 21px;">
                {{$t('index.i_startEarningMoney')}}
              </span>
              <img src="@/assets/images/index/arrowIcon.png" style="max-width: 50%; height: auto; " />
            </div>
          </div>
          <div class="main-2-2">
            <div class="main-2-2-0" >
              {{$t('index.i_cashRewardsForInvitations')}}
            </div>
            <div style="color: rgba(168, 170, 185, 1);font-size: 12px;font-weight: 700;letter-spacing: 1.5px; margin-bottom: 15px">{{$t('index.i_realTimeUpdating')}}</div>
            <div style="position:relative; margin-bottom: 80px">
              
              <div style="font-weight: 900;letter-spacing: 2.7px;font-size: 25px;align-items: center;display: flex; justify-content: center;color: rgba(246, 219, 76, 1);background: linear-gradient(90deg, rgba(35, 37, 58, 1) 0%, rgba(35, 37, 58, 1) 1%, rgba(49, 50, 80, 1) 48.73%, rgba(35, 37, 58, 1) 100%); width:235px; height: 85px;margin:auto">
                <div class="main-2-2-1">
                <img src="@/assets/images/index/dollarIcon.png" style="width: 25px; height: auto;position: absolute; top: -5px; left: 126px " />
                <img src="@/assets/images/index/dollarIcon.png" style="width: 21px; height: auto; position: absolute; top: -23px; left: 158px; transform: rotate(153deg);" />
                <img src="@/assets/images/index/dollarIcon.png" style="width: 21px; height: auto; position: absolute; top: 31px; left: 110px; transform: rotate(165deg);" />
                <img src="@/assets/images/index/dollarIcon.png" style="width: 15px; height: auto; position: absolute; top: -15px; left: 110px;transform: rotate(119deg); " />
              </div>
                $ {{dollarNum}}
              </div>
            </div>
            
            <div class="main-2-2-2">
              {{$t('index.i_inviteFriendsCommission')}}
            </div>
            <div class="main-2-2-3">
              {{$t('index.i_joinCompleteFilling')}}
            </div>
            <div @click="$router.push('/inviteFriend')" style="color: rgba(19, 21, 34, 1);font-weight: 700;letter-spacing: 1.17px;font-size: 15px;display: flex;justify-content: center;  align-items: center;background-color: rgba(81, 215, 120, 1); width:300px; height:50px;border-radius: 10px; margin: auto; margin-bottom: 10%">
              {{$t('index.i_inviteFriendsToMakeMoney')}}
  
            </div>
          </div>
          
        </div>
       
        <!-- 排行榜 -->        
        
        

        <div class="main-3">
          <div class="main-3-1" >
            <img src="@/assets/images/index/leaderboardIcon.png" class="main-3-1-1" />
            <div style="position: relative; font-size: 21px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(255, 255, 255, 1);top: 7px;font-weight: 700;">{{$t('all.i_leaderboard')}}</div>
          </div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <div class="main-3-2-1">
              <div :class="leaderboardType == 1 ? 'leaderboardAct' : ''" @click="leaderboardType = 1;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(178, 177, 209, 1);">{{$t('all.i_todayReward')}}</div>
              <div :class="leaderboardType == 2 ? 'leaderboardAct' : ''" @click="leaderboardType = 2;" style="margin: auto 45px;padding: 4px 8px 4px 6px; color: rgba(178, 177, 209, 1);">{{$t('all.i_totalReward')}}</div>
              <div :class="leaderboardType == 3 ? 'leaderboardAct' : ''" @click="leaderboardType = 3;" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(178, 177, 209, 1)">{{$t('all.i_friends')}}</div>
            </div>
          </div>
          <div v-if="leaderboardType == 1">
            <div v-for="(leaderboard, index) in todayCashLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 10px auto;">
                <div style="float: left;background-color: rgba(35, 53, 63, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 29px;margin-right: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-left: 20px; margin-top: 23px; text-align: left;">
                  <span style="color: rgba(255, 255, 255, 1); font-size: 15px; font-weight: 600;">{{leaderboard.userName}}</span>
                  <span style="text-align: right; display: flex; align-items: center;position: relative; left: -30px">
                    <span style="color: rgba(80, 214, 119, 1);font-size: 13px;">{{leaderboard.num}}</span>
                    <img src="@/assets/common/cash1Icon.png" style="position: relative; width: 14px; vertical-align: middle; left: 7px" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="leaderboardType == 2">
            <div v-for="(leaderboard, index) in cashLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 10px auto;">
                <div style="float: left;background-color: rgba(35, 53, 63, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 29px;margin-right: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-left: 20px; margin-top: 23px; text-align: left;">
                  <span style="color: rgba(255, 255, 255, 1); font-size: 15px; font-weight: 600;">{{leaderboard.userName}}</span>
                  <span style="text-align: right; display: flex; align-items: center;position: relative; left: -30px">
                    <span style="color: rgba(80, 214, 119, 1); font-size: 13px;">{{leaderboard.num}}</span>
                    <img src="@/assets/common/cashIcon.png" style="position: relative; width: 14px; vertical-align: middle; left: 7px" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="leaderboardType == 3">
            <div v-for="(leaderboard, index) in friendLeaderboard" :key="leaderboard.id">
              <div style="overflow: hidden;margin: 10px auto;">
                <div style="float: left;background-color: rgba(35, 53, 63, 1);width: 45px;height: 45px;border-radius: 30px;position: relative;margin-top: 8px;margin-left: 29px;margin-right: 8px;">
                  <img :src="leaderboard.avatar" style="width: 100%;border-radius: 30px;background-color: white;" />
                  <span style="position: absolute;box-sizing: border-box;z-index: 1;left: -6px;top: -6px;background-color: rgba(246, 199, 69, 1);border-radius: 30px;width: 20px;height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle;font-size: 12px;">{{index + 1}}</span>
                  </span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-left: 20px; margin-top: 23px; text-align: left;">
                  <span style="color: rgba(255, 255, 255,1);font-size: 15px;font-weight: 600;">{{leaderboard.userName}}</span>
                  <span style="text-align: right; display: flex; align-items: center;position: relative; left: -30px">
                    <span style="color: rgba(80, 214, 119, 1);font-size: 13px;">{{leaderboard.num}}</span>
                    <img src="@/assets/images/leaderboard003.png" style="position: relative; width: 14px; vertical-align: middle; left: 7px" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <el-button @click="$router.push('/leaderboard')" style="margin-top: 20px;width: 270px;border-radius: 10px;background-color: rgba(39, 37, 58, 1);color: rgba(255, 255, 255, 1);  height: 50px;font-weight: 700;letter-spacing: 2.19px;border: 4px solid rgba(44, 44, 68, 1);">
          {{$t('all.i_viewLeaderboard')}}
          <img src="@/assets/images/index/arrowIcon1.png" style="position: relative; width: 17px; vertical-align: middle; " />
          </el-button>
        </div>


        <div class="main-4">
          <div @click="openTelegram()" class="main-4-1" >
            <img src="@/assets/images/index/communityIcon.png" style="max-width: 39px; height: auto; margin-bottom: 20px;" />
            <div  style="color: rgba(255,255,255,1);font-weight: 400;letter-spacing: 0.61px;">
              
                {{$t('index.i_joinTheOfficialCommunity')}}
              <img src="@/assets/images/index/arrowIcon1.png" style="max-width: 15px; height: auto; " />
            </div>
          </div>
          <div class="main-4-2" >
            <img src="@/assets/images/index/moneyIcon.png" style="max-width: 39px; height: auto; margin-bottom: 20px" />
            <div style="color: rgba(255,255,255,1); font-weight: 400;letter-spacing: 0.61px;">
              <img src="@/assets/images/index/pentagramIcon.png" style="max-width: 25px; height: auto; position: relative; top: 5px; left: -5px" />
              {{userNum}}{{$t('index.i_userHasReceivedCash')}}
              <img src="@/assets/images/index/pentagramIcon.png" style="max-width: 25px; height: auto; position: relative; top: 5px; left: 5px;" />
            </div>
          </div>
          <div class="main-4-3" >
            <img src="@/assets/images/index/likeIcon.png" style="max-width: 39px; height: auto; margin-bottom: 20px; margin-top: -10px" />
            <div style="color: rgba(255,255,255,1); font-weight: 400;letter-spacing: 0.61px;">
              <img src="@/assets/images/index/pentagramIcon.png" style="max-width:25px; height: auto; position: relative; top: 5px; left: -5px" />
              {{likeNum}}{{$t('index.i_goodReviews')}}
              <img src="@/assets/images/index/pentagramIcon.png" style="max-width: 25px; height: auto; position: relative; top: 5px; left: 5px;" />
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: center; flex-direction: column; margin-top: 70px;" >
          <div style="color: rgba(255,255,255,1); font-weight: 700;letter-spacing: 1.61px; margin-bottom: 10px">
            {{$t('index.i_userFeedbackOnBigBigcash2')}}
          </div>
          <div style="color: rgba(125, 126, 157, 1); font-weight: 700;letter-spacing: 1.61px; font-size:12px;  margin-bottom: 30px;">{{$t('index.i_joinBibigCash2AndReview')}}</div>
          <div class="main-4-4">
            <div class="main-4-4-1">
              <div class="main-4-4-1-1">
                  <div class="main-4-4-1-1-1">
                    <img src="@/assets/images/index/starIcon.png" style="width: 139px; margin-right: 73px"/> 
                    <sapn style="color: rgba(125, 126, 157, 1); font-weight: 400; letter-spacing: 0.95px;">10.20.2024</sapn>
                  </div>
                  <div style="text-align: left; color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 1.61px; font-size: 17px; margin-top: 15px;margin-left: 18px">
                    {{$t('index.i_review1')}}
                  </div>
                  <div class="main-4-4-1-1-2">
                    {{$t('index.i_review2')}}
                  </div>
                  <div style="text-align: left; margin-left: 18px; position: absolute; top: 145px;">
                    <img src="@/assets/images/index/avatarIcon2.png" style="width: 33px; margin-right: 9px; border-radius: 33px;"/> 
                    <sapn style="color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 0.95px; position: relative; top: -10px;font-size: 13px">Alvaro Wagner</sapn>
                  </div>
              </div>
            </div>
            <div class="main-4-4-1">
              <div class="main-4-4-1-1">
                <div class="main-4-4-1-1-1">
                    <img src="@/assets/images/index/starIcon.png" style="width: 139px; margin-right: 73px"/> 
                    <sapn style="color: rgba(125, 126, 157, 1); font-weight: 400; letter-spacing: 0.95px;">09.26.2024</sapn>
                  </div>
                  <div style="text-align: left;color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 1.61px; font-size: 17px; margin-top: 15px;margin-left: 18px">
                    {{$t('index.i_review3')}}
                  </div>
                  <div class="main-4-4-1-1-2">
                    {{$t('index.i_review4')}}
                  </div>
                  <div style="text-align: left; position: absolute; top: 145px; margin-left: 18px">
                    <img src="@/assets/images/index/avatarIcon3.png" style="width: 33px; margin-right: 9px; border-radius: 33px;"/> 
                    <sapn style="color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 0.95px; position: relative; top: -10px;font-size: 13px">Marcello Blümel</sapn>
                  </div>
              </div>
            </div>
            <div class="main-4-4-1">
              <div class="main-4-4-1-1">
                  <div class="main-4-4-1-1-1">
                    <img src="@/assets/images/index/starIcon.png" style="width: 139px; margin-right: 73px"/> 
                    <sapn style="color: rgba(125, 126, 157, 1); font-weight: 400; letter-spacing: 0.95px;">09.03.2024</sapn>
                  </div>
                  <div style="text-align: left;color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 1.61px; font-size: 17px; margin-top: 15px;margin-left: 18px">
                    {{$t('index.i_review5')}}
                  </div>
                  <div class="main-4-4-1-1-2">
                    {{$t('index.i_review6')}}
                  </div>
                  <div style="text-align: left; position: absolute; top: 145px; margin-left: 18px">
                    <img src="@/assets/images/index/avatarIcon4.png" style="width: 33px; margin-right: 9px; border-radius: 33px;"/> 
                    <sapn style="color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 0.95px; position: relative; top: -10px;font-size: 13px">Oussama Abida</sapn>
                  </div>
              </div>
            </div>
          </div>
          <div class="main-4-4">
            <div class="main-4-4-1">
              <div class="main-4-4-1-1">
                  <div class="main-4-4-1-1-1">
                    <img src="@/assets/images/index/starIcon.png" style="width: 139px; margin-right: 73px"/> 
                    <sapn style="color: rgba(125, 126, 157, 1); font-weight: 400; letter-spacing: 0.95px;">07.25.2024</sapn>
                  </div>
                  <div style="text-align: left;color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 1.61px; font-size: 17px; margin-top: 15px;margin-left: 18px">
                    {{$t('index.i_review7')}}
                  </div>
                  <div class="main-4-4-1-1-2">
                    {{$t('index.i_review8')}}
                  </div>
                  <div style="text-align: left; position: absolute; top: 145px; margin-left: 18px">
                    <img src="@/assets/images/index/avatarIcon5.png" style="width: 33px; margin-right: 9px; border-radius: 33px;"/> 
                    <sapn style="color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 0.95px; position: relative; top: -10px;font-size: 13px">John Wilson</sapn>
                  </div>
              </div>
            </div>
            <div class="main-4-4-1">
              <div class="main-4-4-1-1">
                  <div class="main-4-4-1-1-1">
                    <img src="@/assets/images/index/starIcon.png" style="width: 139px; margin-right: 73px"/> 
                    <sapn style="color: rgba(125, 126, 157, 1); font-weight: 400; letter-spacing: 0.95px;">07.12.2024</sapn>
                  </div>
                  <div style="text-align: left;color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 1.61px; font-size: 17px; margin-top: 15px;margin-left: 18px">
                    {{$t('index.i_review9')}}
                  </div>
                  <div class="main-4-4-1-1-2">
                    {{$t('index.i_review10')}}
                  </div>
                  <div style="text-align: left; position: absolute; top: 145px; margin-left: 18px">
                    <img src="@/assets/images/index/avatarIcon6.png" style="width: 33px; margin-right: 9px; border-radius: 33px;"/> 
                    <sapn style="color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 0.95px; position: relative; top: -10px;font-size: 13px">John Griffith London</sapn>
                  </div>
              </div>
            </div>
            <div class="main-4-4-1">
              <div class="main-4-4-1-1">
                  <div class="main-4-4-1-1-1">
                    <img src="@/assets/images/index/starIcon.png" style="width: 139px; margin-right: 73px"/> 
                    <sapn style="color: rgba(125, 126, 157, 1); font-weight: 400; letter-spacing: 0.95px;">04.18.2024</sapn>
                  </div>
                  <div style="text-align: left;color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 1.61px; font-size: 17px; margin-top: 15px;margin-left: 18px">
                    {{$t('index.i_review11')}}
                  </div>
                  <div class="main-4-4-1-1-2">
                    {{$t('index.i_review12')}}
                  </div>
                  <div style="text-align: left; position: absolute; top: 145px; margin-left: 18px">
                    <img src="@/assets/images/index/avatarIcon1.png" style="width: 33px; margin-right: 9px; border-radius: 33px;"/> 
                    <sapn style="color: rgba(255, 255, 255, 1); font-weight: 400; letter-spacing: 0.95px; position: relative; top: -10px;font-size: 13px">Alvin Cullum York</sapn>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <div style="margin: auto; display: flex; justify-content: center; align-items: center; flex-direction: column; background-color: rgba(29, 30, 48, 1); height: 138px; margin-top: 100px; margin-bottom: 50px">
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1.09px; margin-bottom: 12px; font-size: 15px;">
              <span @click="openPolicy()" style="">《{{$t('index.i_privacyPolicy')}}》</span>
              <span @click="openService()" style="">《{{$t('index.i_termsOfService')}}》</span>
          </div>
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1px; font-size: 12px">
            © 2020 - 2024 BIgbig Cash 2. All rights reserved.
          </div>
        </div>
        
        <!-- <div>
          <div class="background-container2" style="margin: 4%;padding: 30% 4%;border-radius: 15px; margin-top: 10%">
            <div style= "margin-top: -86px;" >
              <img src="@/assets/images/invite/inviteIcon.png" style="position: relative; width: 20px;vertical-align: middle; left: -8px" />
              <span style="color: rgba(80, 214, 119, 1);font-size: 20px;">{{$t('inviteFriend.i_inviteFriend')}}</span>
              <img src="@/assets/images/invite/inviteIcon.png" style="position: relative; width: 20px;vertical-align: middle; left: 5px" />
            </div>
          <div style="position: relative; display: inline-block;text-align: left; top: 37px;">
                    <span style="color: rgba(255, 255, 255, 1);font-size: 29px;vertical-align: middle;font-weight: bold;">{{maxReward}}</span>
                    <img src="@/assets/common/cashIcon.png" style="width: 23px;vertical-align: middle;" />
          </div>
                  <span style="position: relative; color: rgba(255, 255, 255, 1);font-size: 27px;vertical-align: middle;font-weight: bold; top: 35px; left: 10px;">/{{$t('all.i_friends')}}</span>
          </div>
                <el-button @click="$router.push('/inviteFriend')" style="position: relative; top: -57px; width: 80%;border-radius: 10px;background-color: rgba(80, 214, 119, 1);color: rgba(1, 1, 1, 1);">{{$t('inviteFriend.i_inviteNow')}}</el-button> 
        </div>
        
        <div style="position: relative; overflow: hidden;margin: auto 4%; top: 25px;">
          <div @click="$router.push('/convert')" class="background-container" >
            <span class="cash">{{$t('all.i_variousGifts')}}</span>
            <br />
            <span class="redeemNow">{{$t('all.i_redeemNow')}}</span>
            <br />
            
          </div>
          <div @click="$router.push('/cashLog')" class="background-container1" >
            <span class="details">{{$t('all.i_couponDetails')}}</span>
            <br />
            <span class="rewardRecords">{{$t('all.i_rewardRecords')}}</span>
            <br />
            
          </div>
        </div>


        广告
        <div class="ad">
          <swiper :options="swiperOptions" @click="handleClick">
            <swiper-slide v-for="(item, index) in ads" :key="index" >
              <a :href="item.actionUrl" target="_blank">
                <div  style="width:100%; heigh: 200%; object-fit: cover;">
                  <img :src="item.backgroundImg" class="adImg">
                </div>
              </a>
            </swiper-slide>
            
            <div class="swiper-pagination top-ad" slot="pagination"></div>
            
          </swiper>
        </div>

        
        

        <div style="margin: 4%;">
          <div style=" text-align: left;">
             <img src="@/assets/images/task/taskIcon.png" style="width: 30px;vertical-align: left;" />
             <span style="position: relative; left: 13px; font-size: 25px;text-align: left;color: rgba(255, 255, 255, 1)">{{$t('all.i_task')}}</span>
          </div>
         

          <div style="margin-top: 20px" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <div v-for="(task) in taskList" :key="task.id" style="flex: 1 1 calc(50% - 10px); box-sizing: border-box;">
                <div style="overflow: hidden; margin: 0; padding: 0; background: linear-gradient(180deg, rgba(68, 62, 110, 1) 0%, rgba(48, 49, 75, 1) 100%); border-radius: 10px; height: 290px;">
                  <div style="float: left; height: 60px;">
                    <img @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" :src="task.appIcon" style="position: relative; width: 135px; height: 135px; top: 15px; left: 5px; margin-right: 10px; border-radius: 12px;" />
                    <div @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" style="display: inline-block; vertical-align: top; margin-top: 20px">
                      <span v-html="task.title" style="color: rgba(124, 120, 176, 1); font-size: 15px; font-weight: bold; float: left; margin-bottom: 6px; width: 135px; height: 50px; line-height: 21px; text-align: left; overflow: hidden; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"></span>
                      <br />
                      <el-progress v-if="task.curTaskNum == 0" :show-text="false" :stroke-width="10" :percentage="0" :color="customColors" style="width: 100%; float: left; vertical-align: middle; margin-top: 4px;"></el-progress>
                      <el-progress v-else :show-text="false" :stroke-width="10" :percentage="(task.curTaskNum / task.totalTaskNum) * 100" :color="customColors" style="width: 100%; float: left; vertical-align: middle; margin-top: 4px;"></el-progress>
                      <div style="clear: both; color: rgba(255, 255, 255, 1); font-size: 19px; vertical-align: middle; margin-top: 20px; float: left;">
                        +{{ task.rewardTotalCash }}
                      </div>
                      <img src="@/assets/common/cashIcon.png" style="position: relative; top: 20px; width: 20px; vertical-align: middle; left: -15px;" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="taskList.length % 2 === 1" style="flex: 1 1 calc(50% - 10px); box-sizing: border-box;"></div>
            </div>
          </div>
        </div>-->
      </div> 

      <!-- 联系我们 -->
      <div @click="contactDrawer = true" style="position: fixed;top: 55%;right: 2%;">
        <img src="@/assets/images/index/contactIcon.png" style="width: 40px;" />
      </div>

      <!-- 联系我们Drawer -->
      <el-drawer size="58%" direction="btt" :with-header="false" :show-close="false" :visible.sync="contactDrawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <span style="float: left;font-size: 20px;color: black;">{{ $t('index.i_contact') }}</span>
          </div>
          <div style="margin-top: 6%;">
            <img src="@/assets/images/index/goBrowser.png" style="width: 100px;" />
            <div style="margin-top: 2%;margin-bottom: 5%;color: rgba(127, 127, 149, 1);">{{ $t('index.i_browserOpen') }}</div>
          </div>
          <hr style="width: 80%;" />
          <div style="margin: auto;margin-top: 4%;margin-bottom: 6%;">
            <!-- <div>
              <span @click="goUrl(whatsApp)">
                <img src="@/assets/common/whatsApp.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">WhatsApp</p>
                  <a :href="whatsApp" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{ whatsApp }}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(whatsApp)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div> -->
            <!-- <div style="margin: 4% auto;">
              <span @click="goUrl(meta)">
                <img src="@/assets/common/meta.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">Meta</p>
                  <a :href="meta" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{meta}}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(meta)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div> -->
            <div>
              <span @click="goUrl(telegram)">
                <img src="@/assets/common/telegram.png" style="width: 50px;vertical-align: middle;" />
                <div style="display: inline-block;vertical-align: middle;margin-top: 6px;margin-left: 10px;margin-right: 10px;">
                  <p style="margin: 0;padding: 0;text-align: left;font-weight: bold;">Telegram</p>
                  <a :href="telegram" target="_blank" style="margin: 0;padding: 0;text-align: left;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;width: 150px;">{{ telegram }}</a>
                </div>
              </span>
              <el-button round type="primary" @click="copyVal(telegram)" style="width: 30%;background-color: rgba(0, 111, 255, 1);vertical-align: middle;">
                <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
                &nbsp;
                <span style="font-size: 16px;">Copy</span>
              </el-button>
            </div>
          </div>
        </div>
      </el-drawer>
      <!-- 账户中心 -->
      <el-dialog :title="$t('index.i_accountC')" :visible.sync="userInfoDialog" width="80%"  center>
        <div style="width: 100%;text-align: center;;padding-top: 9%;padding-bottom: 8%;border-radius: 20px;">
          <div style="width: 60px;height: 60px;margin: auto;">
            <img src="@/assets/images/index/avatar.png" style="width: 60px;height: 60px;border-radius: 30px;" />
            <img :src="userInfo.avatar" style="width: 60px;height: 60px;border-radius: 30px;margin-left: -60px;" />
          </div>
          <table style="margin: auto;font-size: 18px;color: rgba(0, 0, 0, 1);margin-top: 10px;">
            <tr>
              <td style="text-align: right;">UID：</td>
              <td style="text-align: left;">{{ userInfo.uid }}</td>
            </tr>
            <tr>
              <td style="text-align: right;">{{$t('all.i_nickName')}}：</td>
              <td style="text-align: left;">{{ userInfo.userName }}</td>
            </tr>
            <tr v-if="userInfo.inviteCode != null">
              <td style="text-align: right;">{{ $t('index.i_inviteCode') }}：</td>
              <td style="text-align: left;">
                <span @click="copyVal(userInfo.inviteCode)">{{ userInfo.inviteCode }}</span>
                <span style="margin-left: 4px;" @click="mobileInfoInit">
                  <img src="@/assets/common/copyIconB.png" style="margin-bottom: 10px;width: 10px;" />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </el-dialog>

      <el-dialog :title="$t('index.i_login')" :visible.sync="loginDialog" width="320px"  @open="initializeGoogleButton">
        <div>
          <!-- <img src="@/assets/common/bigbigCash2.png" style="width: 35%;" /> -->
          <div style="margin-top: 65px; margin-bottom: 15px; color: rgba(255, 255, 255, 1)">
            <div style="font-weight: 700; letter-spacing: 1.03px; font-size: 13px">{{$t('index.i_joinbbc2')}}</div>
          </div>
          <div style="font-size: 20px; font-weight: bold; color: rgba(0, 214, 118, 1); font-weight: 700; letter-spacing: 1.13px; margin-bottom: 50px;">
            {{$t('all.i_playGamesCash')}}
          </div>
          <!-- <div style="color: rgba(93, 95, 136, 1); margin-bottom: 2px; margin-top: 30px; font-size: 13px;">
            Google Sign-in
          </div> -->

          <div id="g_id_onload" 
              data-client_id="96604858165-iepvr7g5irtde3jlsl536abmg16gskpi.apps.googleusercontent.com" 
              data-context="signin" 
              data-ux_mode="popup" 
              data-callback="handleCredentialResponse" 
              data-nonce="" 
              data-itp_support="true">
          </div>

          <div class="g_id_signin"  style="margin: auto;"
              data-type="standard" 
              data-shape="rectangular" 
              data-theme="outline" 
              data-text="signin_with" 
              data-size="large" 
              data-logo_alignment="left">
          </div>

          <div style="margin: auto; display: flex; justify-content: center; align-items: center; flex-direction: column;  margin-top: 30px; margin-bottom: 10px">
            <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 0.59px; font-size: 10px;margin-bottom: 12px">{{$t('index.i_ReadAndAgreedTo')}}</div>
            <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1.09px; margin-bottom: 5px; font-size: 13px">
                <span @click="openPolicy()" style="">《{{$t('index.i_privacyPolicy')}}》</span>
                <span @click="openService()" style="">《{{$t('index.i_termsOfService')}}》</span>
            </div>
          </div>

          <div>
            <img src="@/assets/images/index/remindIcon.png" style="width: 200px; margin-bottom: 7px;" />
          </div>
          <div style="border-radius: 10px;background-color: rgba(30, 27, 45, 1); width: 280px; height: 85px;display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <div style=" color: rgba(73, 67, 107, 1); margin-bottom: 12px; font-size: 12px;">
              ({{$t('all.i_useBrowser')}})
            </div>
            <div>
              <img src="@/assets/images/loginBrowser.png" style="width: 150px;" />
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog :title="$t('index.i_loginException')" :visible.sync="loginErrorDialog" width="320px" >
        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
          <div style="margin-top: 150px; margin-bottom: 10px">
            <img src="@/assets/images/index/errorIcon.png" style="width: 50px;" />
          </div>
          <div style="color: rgba(255, 255, 255, 1); font-weight: 700; letter-spacing: 1.39px; text-align: center; width: 120px;">
            {{$t('index.i_afterReview')}}
          </div>
        </div>
      </el-dialog>


      <!-- 错误信息Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
import DeviceDetector from "device-detector-js";

export default {

  
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      userInfo: {
        uid: 0,
        avatar: "",
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      maxReward: 0,
      failMessage: null,
      checkDialog: false,
      userInfoDialog: false,
      contactDrawer: false,
      loginDialog: false,
      loginErrorDialog: false,
      // whatsApp: "https://chat.whatsapp.com/BsP9LYv73k2E6NpjphgAUf",
      // meta: "https://www.facebook.com/profile.php?id=61554439329069",
      telegram: "https://t.me/+6iNEoHf2HaYxM2Zl",
      contact: "https://www.facebook.com/people/Puzzle-Story/100089158787963/",
      leaderboardType: 1,
      customColors: "rgba(237, 179, 77, 1)",
      taskList: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      todayCashLeaderboard: [],
      cashLeaderboard: [],
      friendLeaderboard: [],
      ads: [],
      swiperOptions: {
        pagination: {
          el: '.top-ad',
          clickable: true
        },
        autoplay: {
        delay: 15000, // 每5秒自动切换
        disableOnInteraction: false, // 交互后继续自动播放
        },
        loop: true, // 循环
        // 其他选项...
        //  on:{
        //   click: function(event){
        //     console.log((this.activeIndex));
        //     a(this.activeIndex)
        //   },
        // },
      },
      swiperOption1: {
         slidesPerView: 'auto',
         spaceBetween: -70,
         loop: true,
         grid: {
            fill: 'column',
            rows: 1,
         },
         speed:9500,
         freeMode:true,

            autoplay: {

              delay:0,
              disableOnInteraction: false,
            },
          loopAdditionalSlides: 1
      },
      swiperOption2: {
            slidesPerView: 'auto', // 根据内容自适应
            spaceBetween: -130, // 设置每个幻灯片之间的空间
            centeredSlides: true, // 中心幻灯片
            centeredSlidesOffset: 1000,
            loop: true, // 启用循环
            freeMode:true,

            autoplay: {

              delay:0,
              disableOnInteraction: false,
            },
            loopFillGroupWithBlank: true,
            
            normalizeSlideIndex:true,
            loop: true,//开启环路
            speed: 6000,//切换速度
            
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            

            // 其他选项...
        },
        

      length: 0,
      dollarNum: 51212,
      userNum: 422122,
      likeNum: 4221022,
    };
  },

  
  methods: {

    openService() {
      window.open(window.location.origin+"/BigBigSoftTermsOfService.html", "_blank");
    },

    openPolicy() {
      window.open(window.location.origin+"/BigBigSoftPrivacyPolicy.html", "_blank");
    },

    openTelegram() {
      window.open(this.telegram, "_blank");
    },

    // 确保 Google 登录按钮在弹窗打开后初始化
  initializeGoogleButton() {
    let script = document.createElement("script"); //创建script标签
    script.type = "text/javascript";
    script.src = "https://accounts.google.com/gsi/client"; //外部js文件地址
    document.body.appendChild(script); //外部链接需要调用（本地不用）
    this.$nextTick(() => {
      setTimeout(() => {
        if (
          document.getElementsByClassName("g_id_signin")[0].childElementCount ==
          0
        ) {
          this.checkBrowserDrawer = true;
        }
      }, 1200);
    });
  },
    handleClick(event) {
      
      const slide = event.target.closest('.swiper-slide')
      const adId = this.ads[slide.getAttribute('data-swiper-slide-index')].adId
      this.add(adId)
    },
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },

    mobileInfoInit() {
    // 设备信息
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    let device = deviceDetector.parse(userAgent);
    
    console.log(device)
    },

    getIndex() {
      
      this.isLoading = true;
      this.$axios({
        url: "/webCash/index",
        method: "get",
        params: {
            inviteCode: this.$route.query.referrer
        }
      }).then((result) => {
        
        this.isLoading = false;
        if (result.data.code == 1) {
          this.maxReward = result.data.data.maxReward;
          this.userInfo = result.data.data.userInfo;
          // this.failMessage = result.data.data.failMessage;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          if (result.data.data.userInfo != null) {
            sessionStorage.setItem(
              "userInfo",
              JSON.stringify(result.data.data.userInfo)
            );
          } else {
            sessionStorage.removeItem("userInfo");
          }
        }else{
          if(this.loginErrorDialog == false){
            this.loginDialog = true;
          }
        }
      });
      this.isLoading = false;
    },
    getLeaderboard() {
      
      this.isLoading = true;
      this.$axios({
        url: "/webCash/leaderboard",
        method: "get",
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.todayCashLeaderboard =
            result.data.data.leaderboard.todayCashLeaderboard;
          if (result.data.data.leaderboard.todayCashLeaderboard == null) {
            this.leaderboardType = 2;
          }
          this.cashLeaderboard = result.data.data.leaderboard.cashLeaderboard;
          this.friendLeaderboard =
            result.data.data.leaderboard.friendLeaderboard;
        }
      });
      this.isLoading = false;
    },
    goApp() {
      window.location.href = this.userInfo.appScheme;
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },


    loadMore: function () {
      if (this.isLast != true) {       
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;  
        this.$axios({
          url: "/webCash/taskList",
          method: "get",
          params: {
            pageNum: this.pageNum,
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {           
            result.data.data.taskList.forEach((element) => {
              this.taskList.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
          
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
      this.isLoading = false;
    },

    getAds() {
        this.$axios({
          url: "/webCash/adList",
          method: "get",
          params: {
            tagId: 0,
          }
        }).then((result) => {  
          if (result.data.code == 1) {           
            result.data.data.ads.forEach((element) => {
              this.ads.push(element);
            });
            this.length = this.ads.length;
          }  
          
        });
        
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
    },

    add(adId) {
        this.$axios({
          url: "/webCash/add",
          method: "get",
          params: {
            operateType: 2,
            adId: adId,
          }
        }).then((result) => {  
           
        });

    },
    Increment() {
        this.dollarNum = this.dollarNum + Math.floor((Date.now()-1729331399797)/1000)
        this.userNum = this.userNum + (Math.floor((Date.now()-1729331399797)/10000) + Math.floor(Math.random() * 10))
        this.likeNum = this.likeNum + (Math.floor((Date.now()-1729331399797)/5000) + Math.floor(Math.random() * 10))
        
      
    },
    
  },

  created() {
    
    if(window.location.hash.split('?')[1] == 0){
      this.loginErrorDialog = true;
      this.loginDialog = false;
      location.href = "/#/index"
    }
    // if (this.$route.query.token != null) {
    //   localStorage.setItem("token", this.$route.query.token);
    // }
    
    // this.getAds();
    this.getIndex();
    // this.loadMore();
    this.Increment();
    this.getLeaderboard();
    
  }
};
</script>

<style>

#index-layout .swiper-slide:first-child {
  margin-right: 48px; 
}

.swiper-container-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    margin: 0 auto !important;
}


  #index-layout {
    max-width: 130rem;;
    margin: auto;
  }




#index-layout .index-top {
  padding-bottom: 2%;
}

#index-layout .i-page-header {
  overflow: hidden;
  
  background-color: rgba(28, 29, 47, 1)
}

#index-layout .el-page-header__left::after {
  display: none;
}

#index-layout .i-gift-code-btn,
#index-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#index-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

#index-layout .leaderboardAct {
  background-color: rgba(80, 270, 119, 1);
  color: rgba(35, 53, 63, 1)!important;
  border-radius: 30px;
}

#index-layout .el-dialog__header {
  display: none; /* 隐藏头部 */
}
#index-layout .el-dialog__body{
  background: linear-gradient(335.12deg, rgba(72, 40, 77, 1) 0%, rgba(72, 40, 77, 1) 1.12%, rgba(39, 31, 54, 1) 46%, rgba(44, 91, 76, 1) 100%) !important;
  border-radius:15px !important;
  height: 400px;
  padding-top: 10px !important;
}

#index-layout .g_id_signin div[role="button"] {
  width: 220px !important;
  margin: auto !important;
}
#index-layout .g_id_signin iframe {
  display: inline !important;
}

.main-top{
  display: flex;
  justify-content: center; 
  align-items: center;
  background-color: rgba(26, 28, 41, 1); 
  width: 155px; 
  height: 37px; 
  border-radius: 7px;
}
.main-top-1 {
  max-width: 26%; 
  height: auto; 
  margin-right: 10px
}
.main-top-2 {
  background-color: rgba(56, 51, 76, 1); 
  width:53px; 
  height: 20px; 
  border-radius: 3px
}



@media screen and (min-width: 1080px) {
  .top-button{
    
    float: left;
    width: 550px; 
    margin-bottom: -2px;
    background: rgba(19, 21, 34, 1);
    height: 70px; 
    margin-top: -20px; 
    margin-left: 22px;
  }
  .top-button1{
    display: flex; 
    align-items: center; 
    margin-left: 27px; 
    margin-top: 17px
  }
  
  

}



@media screen and (max-width: 1279px) {
  .my-swiper {
    width: 92%; /*设置Swiper的宽度*/
    overflow: hidden; /* 隐藏溢出的内容 */
    margin: auto; /* 水平居中 */
  }

  #index-layout .my-swiper1 {
    width: 92%; /*设置Swiper的宽度*/
    overflow: hidden; /* 隐藏溢出的内容 */
    margin: auto; /* 水平居中 */
  }
  
  .main-0{
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    margin-right: 20px;
  }
  .top-button{
    /* float: left; */
    width: 100%;
    margin-bottom: 2px;
    background: rgb(19, 21, 34);
    height: 62px;
    margin-left: -10px;
    position: fixed;
    z-index: 2;
    bottom: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top-button1{
    display: flex; 
    align-items: center; 
    /* margin-left: 475px;  */
    margin-top: 11px
  }
  
  #index-layout .main-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 250px; 
    padding: 118px 18px;
  }
  #index-layout .main-1-1{
    width: 92%; 
    /* max-width: 345px;  */
    height: auto; 
    min-height: 400px; 
    background-color: rgba(34, 35, 55, 1); 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    /* color: white;  */
    padding: 20px; 
    flex-direction: column;
  }
  #index-layout .main-1-1-1{
    background-color: rgba(29, 30, 48, 1); 
    width: 95%; 
    height: 108px; 
    border-radius: 15px; 
    color: rgba(168, 167, 198, 1); 
    font-weight: 700; 
    letter-spacing: 1.03px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    padding: 10px;
    text-align: left;
    justify-content: center;
  }
  #index-layout .main-2{
    display: flex;
    justify-content: center;  
    align-items: center; 
    margin-top:45px;
    flex-direction: column;
  }
  #index-layout .main-2-1{
    background: linear-gradient(308.07deg, rgba(50, 39, 81, 1) 0%, rgba(50, 39, 81, 1) 1%, rgba(29, 30, 48, 1) 48.75%, rgba(76, 106, 70, 1) 100%); 
    width: 92%; 
    height:510px; 
    margin-bottom: 30px;
    border-radius: 10px;
  }
  #index-layout .main-2-1-1{
    margin-top: 90px; 
    margin-bottom: 20px
  }
  #index-layout .main-2-1-2{
    color: rgba(168, 170, 185, 1);
    font-weight: 400;
    letter-spacing: 1.17px;
    font-size: 15px;
    text-align: left; 
    width: 90%; 
    margin-left: 6%;
    margin-bottom: 75px;
    font-size: 13px
  }
  #index-layout .main-2-2{
    border-radius: 10px;
    background: linear-gradient(235.07deg, rgba(60, 58, 79, 1) 0%, rgba(26, 27, 44, 1) 100%);
    width: 92%; 
    /* height: 510px */
  }

  #index-layout .main-2-2-0{
    margin-top: 60px; 
    margin-bottom: 10px;
    color: rgba(168, 170, 185, 1);
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 1.5px;
  }

  #index-layout .main-2-2-1{
    position: relative;
    left: -160px;
    top:-35px;
  }
  #index-layout .main-2-2-2{
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    letter-spacing: 1.17px;
    font-size: 15px;
    text-align: left; 
    width: 280px; 
    margin-left: 7%;
    margin-bottom: 15px;
    margin-top:-49px;
  }
  #index-layout .main-2-2-3{
    color: rgba(168, 170, 185, 1);
    font-weight: 400;
    letter-spacing: 1.17px;
    font-size: 13px;
    text-align: left; 
    width: 85%; 
    margin-left: 7%;
    margin-bottom: 10%;
    /* height:120px */
  }

  #index-layout .main-3{
    border-radius: 10px;
    height: 486px;
    width: 92%;
    background: linear-gradient(207.74deg, rgba(26, 28, 40, 1) 0%, rgba(41, 44, 69, 1) 100%); 
    margin-top: 5%; 
    margin: auto;
  }
  #index-layout .main-3-1{
    position: relative; 
    text-align: left; 
    padding: 10px; 
    margin-top: 27px; 
    margin-left: 13px;
  }
  #index-layout .main-3-1-1{
    width: 30px;
    vertical-align: middle;
    margin-top: 20px;
  }
  #index-layout .main-3-2-1{
    display: flex; 
    justify-content: center;
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 36px; 
    background-color: rgba(52, 57, 87, 1); 
    width: 293px;
    border-radius:20px; 
    height: 35px
  }
  #index-layout .main-4{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-top: 20px; 
    flex-direction: column;
   
  }
  #index-layout .main-4-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 92%; 
    height: 160px; 
    margin-bottom: 20px;
    border-radius: 18px;
  }
  #index-layout .main-4-2{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background-color: rgba(29, 30, 48, 1); 
    width: 92%; 
    height: 160px; 
    margin-bottom: 20px; 
    border-radius: 18px;
  }
  #index-layout .main-4-3{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 92%; 
    height: 160px; 
    border-radius: 18px;
  }
  #index-layout .main-4-4{
    display: flex; 
    justify-content: center;
    flex-direction: column;
  }
  #index-layout .main-4-4-1{
    width: 92%;
    height: 220px;
    border-radius: 10px;
    background: rgba(29, 30, 48, 1);
    margin: auto;
    margin-bottom: 15px;
  }
  #index-layout .main-4-4-1-1{
    /* max-width: 345px;  */
    height: auto; 
    position: relative;
  }
  #index-layout .main-4-4-1-1-1{
    margin-top: 22px;
    display: flex; 
    justify-content: space-between; 
    margin-left: 17px; 
    margin-right: 13px;
  }
  #index-layout .main-4-4-1-1-2{
    width: 300px; 
    text-align: left; 
    color: rgba(125, 126, 157, 1); 
    font-weight: 400; 
    letter-spacing: 1.61px; 
    font-size: 10px; 
    margin-top: 15px;
    margin-left: 18px
  }
}


@media screen and (min-width: 765px) {
  #index-layout .main-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 300px; 
    padding: 118px 25px;
  }
  /* #index-layout .main-1-1{
    width: 119%; 
    max-width: 710px; 
    height: auto; 
    min-height: 400px; 
    background-color: rgba(34, 35, 55, 1); 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
    flex-direction: column;
  } */
  /* #index-layout .main-1-1-1{
    background-color: rgba(29, 30, 48, 1); 
    width: 690px; 
    height: 103px; 
    border-radius: 15px; 
    color: rgba(168, 167, 198, 1); 
    font-weight: 700; 
    letter-spacing: 1.03px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    padding: 10px;
    text-align: left;
  } */
  
}

@media screen and (min-width: 1280px) {
 .main-0{
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    margin-right: 55px;
  }
  #index-layout .main-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 400px; 
    padding: 118px 25px;
  }
  #index-layout .main-1-1{
    width: 119%; 
    max-width: 1160px; 
    height: auto; 
    min-height: 450px; 
    background-color: rgba(34, 35, 55, 1); 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    /* color: white;  */
    padding: 20px; 
    flex-direction: column;
  }
  #index-layout .main-1-1-1{
    background-color: rgba(29, 30, 48, 1); 
    width: 1090px; 
    height: 108px; 
    border-radius: 15px; 
    color: rgba(168, 167, 198, 1); 
    font-weight: 700; 
    letter-spacing: 1.03px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    padding: 10px;
    margin-top: 10px;
    text-align: left;
    justify-content: center;
  }

  #index-layout .my-swiper {
    width: 1200px; /*设置Swiper的宽度*/
    overflow: hidden; /* 隐藏溢出的内容 */
    margin: auto; /* 水平居中 */
  }

  #index-layout .my-swiper1 {
    width: 1200px; /*设置Swiper的宽度*/
    overflow: hidden; /* 隐藏溢出的内容 */
    margin: auto; /* 水平居中 */
  }
  
  #index-layout .main-2{
    display: flex;
    justify-content: center;  
    align-items: center; 
    margin-top:45px
  }
  #index-layout .main-2-1{
    background: linear-gradient(308.07deg, rgba(50, 39, 81, 1) 0%, rgba(50, 39, 81, 1) 1%, rgba(29, 30, 48, 1) 48.75%, rgba(76, 106, 70, 1) 100%); 
    width: 575px; 
    height:600px; 
    margin-right: 50px;
    border-radius: 10px;
  }
   #index-layout .main-2-1-1{
    margin-top: 130px; 
    margin-bottom: 20px
  }
  #index-layout .main-2-1-2{
    color: rgba(168, 170, 185, 1);
    font-weight: 400;
    letter-spacing: 1.17px;
    font-size: 15px;
    text-align: left; 
    width: 466px; 
    margin-left: 60px;
    margin-bottom: 75px;
    font-size: 15px;
  }
  #index-layout .main-2-2{
    border-radius: 10px;
    background: linear-gradient(235.07deg, rgba(60, 58, 79, 1) 0%, rgba(26, 27, 44, 1) 100%);
    width: 575px; 
    height: 600px;
  }

  #index-layout .main-2-2-0{
    margin-top: 85px; 
    margin-bottom: 10px;
    color: rgba(168, 170, 185, 1);
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 1.5px;
  }

  

  #index-layout .main-2-2-2{
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    letter-spacing: 1.17px;
    font-size: 16px;
    text-align: left; 
    width: 160px; 
    margin-left: 45px;
    margin-bottom: 15px;
    margin-top: -30px;
  }
 
  #index-layout .main-2-2-3{
    color: rgba(168, 170, 185, 1);
    font-weight: 400;
    letter-spacing: 1.17px;
    font-size: 15px;
    text-align: left; 
    width: 490px; 
    margin-left: 45px;
    margin-bottom: -5px;
    height: 120px;
  }

  #index-layout .main-3{
    border-radius: 10px;
    height: 500px;
    width: 1200px;
    background: linear-gradient(207.74deg, rgba(26, 28, 40, 1) 0%, rgba(41, 44, 69, 1) 100%); 
    margin-top: 5%; 
    margin: auto;
  }

  #index-layout .main-3-1{
    position: relative; 
    text-align: left; 
    padding: 4%; 
    margin-top: 27px; 
    margin-left: -9px;
  }
  #index-layout .main-3-1-1{
    width: 30px;
    vertical-align: middle;
    margin-top: -7px;
  }
  #index-layout .main-3-2-1{
    display: flex; 
    justify-content: center;
    font-size: 12px;
    /* margin-top: -17px; */
    margin-bottom: 8px; 
    background-color: rgba(52, 57, 87, 1); 
    width: 293px;
    border-radius:20px; 
    height: 35px

    /* display: flex; 
    justify-content: center;
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 36px; 
    background-color: rgba(52, 57, 87, 1); 
    width: 293px;
    border-radius:20px; 
    height: 35px */
  }

  #index-layout .main-4{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-top: 26px; 
    
  }
  #index-layout .main-4-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 387px; 
    height: 160px; 
    margin-right: 20px;
    border-radius: 18px;
  }
  #index-layout .main-4-2{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background-color: rgba(29, 30, 48, 1); 
    width: 387px; 
    height: 160px; 
    margin-right: 20px; 
    border-radius: 18px;
  }
  #index-layout .main-4-3{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 387px; 
    height: 160px; 
    border-radius: 18px;
  }
  #index-layout .main-4-4{
    display: flex; 
    justify-content: center;
    margin-top: 20px;
    margin-left: 18px;
  }
  #index-layout .main-4-4-1{
    width: 387px;
    height: 220px;
    border-radius: 10px;
    background: rgba(29, 30, 48, 1);
    margin-right: 20px;
  }
  #index-layout .main-4-4-1-1{
    max-width: 335px; 
    height: auto; 
    position: relative;
  }
  #index-layout .main-4-4-1-1-1{
    margin-top: 22px;
    display: flex; 
    justify-content: space-between; 
    margin-left: 17px; 
    margin-right: -29px;
  }
  #index-layout .main-4-4-1-1-2{
    width: 350px; 
    text-align: left; 
    color: rgba(125, 126, 157, 1); 
    font-weight: 400; 
    letter-spacing: 1.61px; 
    font-size: 10px; 
    margin-top: 15px;
    margin-left: 18px
  }
}


/* @media screen and (min-width: 1500px) {
  .my-swiper1 {
  width: 1935px;
  overflow: hidden; 
  margin: auto; 
  }
} */







@media screen and (max-width: 767px) {
  .background-container {
      background-image: url('@/assets/images/index/convertIcon.png'); /* 替换为你的图片路径 */
      background-size: cover; /* 使背景图像覆盖整个容器 */
      background-position: center; /* 将图像居中显示 */
      background-repeat: no-repeat; /* 防止图像重复 */
      float: left;
      padding: 3%;
      border-radius: 15px;
      text-align: left;
      width: 41%; 
      height: 71px
    /* 如果需要，可以保留颜色作为备选背景 */
    
  }
  .cash {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
  }
  .redeemNow{
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
  }

  .background-container1 {
    background-image: url('@/assets/images/index/logIcon.png'); /* 替换为你的图片路径 */
    background-size: cover; /* 使背景图像覆盖整个容器 */
    background-position: center; /* 将图像居中显示 */
    background-repeat: no-repeat; /* 防止图像重复 */
    /* 如果需要，可以保留颜色作为备选背景 */
    float: right;
    padding: 3%;
    border-radius: 15px;
    text-align: left;
    width: 41%; 
    height: 83px
  }
  .details{
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
  }
  .rewardRecords{
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
  }

  /* .ad{
    margin-top: 37px; 
    margin-bottom: -45px
  }
  .adImg{
    width: 93%; 
    border-radius: 12px;
  } */
}


@media screen and (min-width: 768px) {
  .background-container {
      background-image: url('@/assets/images/index/convertIcon.png'); /* 替换为你的图片路径 */
      background-size: cover; /* 使背景图像覆盖整个容器 */
      background-position: center; /* 将图像居中显示 */
      background-repeat: no-repeat; /* 防止图像重复 */
      float: left;
      padding: 5%;
      border-radius: 25px;
      text-align: left;
      width: 33%; 
      height: 71px
    /* 如果需要，可以保留颜色作为备选背景 */
    
  }
  .cash {
    font-size: 25px;
    color: rgba(255, 255, 255, 1);
    position:relative;
    top:-20px;
    left:-13px;
  }
  .redeemNow{
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
     position:relative;
    top:-12px;
    left:-13px;
  }

  .background-container1 {
    background-image: url('@/assets/images/index/logIcon.png'); /* 替换为你的图片路径 */
    background-size: cover; /* 使背景图像覆盖整个容器 */
    background-position: center; /* 将图像居中显示 */
    background-repeat: no-repeat; /* 防止图像重复 */
    /* 如果需要，可以保留颜色作为备选背景 */
    float: right;
    padding: 5%;
    border-radius: 25px;
    text-align: left;
    width: 33%; 
    height: 83px
  }
  .details{
    font-size: 25px;
    color: rgba(255, 255, 255, 1);
    position:relative;
    top:-20px;
    left:-13px;
  }
  .rewardRecords{
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    position:relative;
    top:-12px;
    left:-13px;
  }

  /* .ad{
    margin-top: 37px; 
    margin-bottom: -45px
  }
  .adImg{
    width: 93%; 
    border-radius: 12px;
  } */
}

@media screen and (min-width: 1024px) {
  .background-container {
      background-image: url('@/assets/images/index/convertIcon.png'); /* 替换为你的图片路径 */
      background-size: cover; /* 使背景图像覆盖整个容器 */
      background-position: center; /* 将图像居中显示 */
      background-repeat: no-repeat; /* 防止图像重复 */
      float: left;
      padding: 63px;
      border-radius: 35px;
      text-align: left;
      width: 250px; 
      height: 71px
    /* 如果需要，可以保留颜色作为备选背景 */
    
  }
  .cash {
    font-size: 35px;
    color: rgba(255, 255, 255, 1);
    position:relative;
    top:-30px;
    left:-38px;
  }
  .redeemNow{
    font-size: 39px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    position:relative;
    top:-10px;
    left:-38px;
  }


  .background-container1 {
    background-image: url('@/assets/images/index/logIcon.png'); /* 替换为你的图片路径 */
    background-size: cover; /* 使背景图像覆盖整个容器 */
    background-position: center; /* 将图像居中显示 */
    background-repeat: no-repeat; /* 防止图像重复 */
    /* 如果需要，可以保留颜色作为备选背景 */
    float: right;
    padding: 63px;
    border-radius: 35px;
    text-align: left;
    width: 250px; 
    height: 83px
  }
  .details{
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
    position:relative;
    top:-30px;
    left:-38px;
  }
  .rewardRecords{
    font-size: 39px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    position:relative;
    top:-10px;
    left:-38px;
  }

/* .ad{
    margin-top: 37px; 
    margin-bottom: -45px
  }
  .adImg{
    width: 93%; 
    border-radius: 12px;
  } */
}

@media screen and (min-width: 1025px) {
  /* .ad{
    margin-top: -179px; 
    margin-bottom: -45px
  }
  .adImg{
    width: 48%; 
    border-radius: 12px;
  } */
}



.background-container2 {
  background-image: url('@/assets/images/invite/invitebackground.png'); /* 替换为你的图片路径 */
  background-size: 392px 200px;; /*使背景图像覆盖整个容器  */
  background-position: center; /* 将图像居中显示 */
  background-repeat: no-repeat; /*防止图像重复 */
  
}

.swiper-pagination.top-ad {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

#index-layout .swiper{
  width:230px !important;
  
}



#index-layout .my-swiper1 .swiper-slide:not(.swiper-slide-active) {
    opacity: 0.5; /* 其他图片变得透明 */
    
}




</style>
