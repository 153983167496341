<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="leaderboard-layout">
      <!-- 导航条 -->
      <div class="l-page-header" style="padding: 25px 11px 0 11px;">
        <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div class="main-1">
              <div @click="$router.push('/index')" style="display: flex; justify-content: center; align-items: center; background: rgba(40, 37, 56, 1); width: 80px; height: 33px; ">
                  <img src="@/assets/images/index/arrowIcon2.png" style="max-width: 18px; height: auto; margin-right: 6px" />
                  <div style="color: rgba(164, 159, 191, 1); font-weight: 700; letter-spacing: 1.46px; font-size: 15px">{{$t('all.i_back')}}</div>
              </div>
        </div>
      </div>
      <!-- <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p> -->

      <div>
        <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 100px;">
            <div class="main-2">
              <div :class="actLeaderboard == 1 ? 'leaderboardAct' : ''" @click="actLeaderboard=1;toTodayCashLeaderboard()" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(173, 170, 201, 1);">{{$t('all.i_todayReward')}}</div>
              <div :class="actLeaderboard == 2 ? 'leaderboardAct' : ''" @click="actLeaderboard=2;toGoldLeaderboard()" style="margin: auto 45px;padding: 4px 8px 4px 6px; color: rgba(173, 170, 201, 1);">{{$t('all.i_totalReward')}}</div>
              <div :class="actLeaderboard == 3 ? 'leaderboardAct' : ''" @click="actLeaderboard=3;toFriendLeaderboard()" style="margin: auto 4px;padding: 4px 8px 4px 6px; color: rgba(173, 170, 201, 1)">{{$t('all.i_friends')}}</div>
            </div>

            <!-- <div style="">
          <el-button @click="actLeaderboard=1;toTodayCashLeaderboard()" :class="actLeaderboard==1?'actShow':''" size="medium" style="background-color: rgba(235, 236, 238, 1);border-radius: 10px 10px 0 0;border: none;color: rgba(48, 127, 95, 1);">{{$t('all.i_todayReward')}}</el-button>
          <el-button @click="actLeaderboard=2;toGoldLeaderboard()" :class="actLeaderboard==2?'actShow':''" size="medium" style="background-color: rgba(235, 236, 238, 1);border-radius: 10px 10px 0 0;border: none;color: rgba(48, 127, 95, 1);">{{$t('all.i_totalReward')}}</el-button>
          <el-button @click="actLeaderboard=3;toFriendLeaderboard()" :class="actLeaderboard==3?'actShow':''" size="medium" style="background-color: rgba(235, 236, 238, 1);border-radius: 10px 10px 0 0;border: none;color: rgba(48, 127, 95, 1);">{{$t('all.i_friends')}}</el-button>
        </div> -->
        </div>
        <div v-if="actLeaderboard==1" style="padding-bottom: 20px;">
          <div style="position: relative;margin-bottom: 5px;">
            <!-- 前三名排行榜背景图 -->
            <img src="@/assets/images/index/leaderboardIcon1.png" style="width: 310px;" />
            <span v-if="leaderboard != null && leaderboard.length >= 1">
              <!-- 皇冠 -->
              <span class="crown" style="z-index: 3;">
                <img src="@/assets/images/leaderboard002.png" style="width: 68px;" />
              </span>
              <!-- 第一名的默认头像 -->
              <span class="first-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 68px;border-radius: 50px;" />
              </span>
              <!-- 第一名的头像 -->
              <span class="first-avatar" style="z-index: 2;">
                <img :src="leaderboard[0].avatar" style="width: 68px;border-radius: 50px;" />
              </span>
              <!-- 第一名的名字和总金币数 -->
              <span class="first-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[0].userName.length > 14">
                  <marquee scrolldelay="300" style="width: 90px;color: white;">{{leaderboard[0].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="display: inline-block;margin-bottom: 4px;color: white;">{{leaderboard[0].userName}}</span>
                  <br />
                </span>
                <!-- 总金币数 -->
                <span style="color: white;vertical-align: middle;">{{leaderboard[0].num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" />
              </span>
            </span>
            <span v-if="leaderboard != null && leaderboard.length >= 2">
              <!-- 第二名的默认头像 -->
              <span class="second-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第二名的头像 -->
              <span class="second-avatar" style="z-index: 2;">
                <img :src="leaderboard[1].avatar" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第二名的名字和总金币数 -->
              <span class="second-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[1].userName.length > 8">
                  <marquee scrolldelay="300" style="width: 60px;color: white;">{{leaderboard[1].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="display: inline-block;margin-bottom: 4px;color: white;">{{leaderboard[1].userName}}</span>
                  <br />
                </span>
                <!-- 总金币数 -->
                <span style="color: white;vertical-align: middle;">{{leaderboard[1].num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </span>
            <span v-if="leaderboard != null && leaderboard.length >= 3">
              <!-- 第三名的默认头像 -->
              <span class="third-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第三名的头像 -->
              <span class="third-avatar" style="z-index: 2;">
                <img :src="leaderboard[2].avatar" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第三名的名字和总金币数 -->
              <span class="third-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[2].userName.length > 8">
                  <marquee scrolldelay="300" style="width: 60px;color: white;">{{leaderboard[2].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="display: inline-block;margin-bottom: 4px;color: white;">{{leaderboard[2].userName}}</span>
                  <br />
                </span>
                <!-- 总金币数 -->
                <span style="vertical-align: middle;color: white;">{{leaderboard[2].num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" />
              </span>
            </span>
          </div>
          <div class="main-3">
            <div style="padding-top: 2%;"></div>
            <!-- 我的排行榜 -->
            <div v-if="myLeaderboard != null" style="background-color: rgba(32, 31, 45, 1);margin-top: 5px;overflow: auto;padding: 10px 20px;color: black;">
              <span style="width: 33px;float: left;vertical-align: middle;margin-top: 8px; color: rgba(255,255,255,1); margin-right: 4px; margin-left: -16px; text-align: right;">{{myLeaderboardIndex != null ? myLeaderboardIndex : '100+'}}</span>
              <span :style="{'text-align': 'left','float': 'left','width': (88 - (10 + (myLeaderboard.num + '').length * 3)) + '%','vertical-align': 'middle'}">
                <span style="position: relative;margin-left: 8px;margin-right: 8px;">
                  <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                  <img :src="myLeaderboard.avatar" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                </span>
                <span style="vertical-align: middle;width: 60%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis; color: rgba(255,255,255,1)">{{myLeaderboard.userName}}</span>
              </span>
              <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': (10 + (myLeaderboard.num + '').length * 3) + '%'}">
                <span style="vertical-align: middle; color: rgba(255,255,255,1)">{{myLeaderboard.num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </div>
            <!-- 排行榜 -->
            <div v-for="(leaderboard, index) in leaderboard" :key="index">
              <div v-if="index+1>3" style="margin-top: 10px;overflow: hidden;padding: 10px 20px;color: rgba(255,255,255,1)">
                <span style="width: 20px;float: left;vertical-align: middle;margin-top: 8px;">{{index+1}}</span>
                <span :style="{'text-align': 'left','float': 'left','width': (88 - (10 + (leaderboard.num + '').length * 3)) + '%','vertical-align': 'middle'}">
                  <span style="position: relative;margin-left: 8px;margin-right: 8px;">
                    <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                    <img :src="leaderboard.avatar" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                  </span>
                  <span style="vertical-align: middle;width: 60%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;">{{leaderboard.userName}}</span>
                </span>
                <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': (10 + (leaderboard.num + '').length * 3) + '%'}">
                  <span style="vertical-align: middle;">{{leaderboard.num}} </span>
                  <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="actLeaderboard==2" style="padding-bottom: 20px;">
          <div style="position: relative;margin-bottom: 5px;">
            <!-- 前三名排行榜背景图 -->
            <img src="@/assets/images/index/leaderboardIcon1.png" style="width: 310px;" />
            <span v-if="leaderboard != null && leaderboard.length >= 1">
              <!-- 皇冠 -->
              <span class="crown" style="z-index: 3;">
                <img src="@/assets/images/leaderboard002.png" style="width: 68px;" />
              </span>
              <!-- 第一名的默认头像 -->
              <span class="first-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 68px;border-radius: 50px;" />
              </span>
              <!-- 第一名的头像 -->
              <span class="first-avatar" style="z-index: 2;">
                <img :src="leaderboard[0].avatar" style="width: 68px;border-radius: 50px;" />
              </span>
              <!-- 第一名的名字和总金币数 -->
              <span class="first-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[0].userName.length > 14">
                  <marquee scrolldelay="300" style="width: 90px;color: white;">{{leaderboard[0].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="display: inline-block;margin-bottom: 4px;color: white;">{{leaderboard[0].userName}}</span>
                  <br />
                </span>
                <!-- 总金币数 -->
                <span style="color: white;vertical-align: middle;">{{leaderboard[0].num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" />
              </span>
            </span>
            <span v-if="leaderboard != null && leaderboard.length >= 2">
              <!-- 第二名的默认头像 -->
              <span class="second-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第二名的头像 -->
              <span class="second-avatar" style="z-index: 2;">
                <img :src="leaderboard[1].avatar" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第二名的名字和总金币数 -->
              <span class="second-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[1].userName.length > 8">
                  <marquee scrolldelay="300" style="width: 60px;color: white;">{{leaderboard[1].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="display: inline-block;margin-bottom: 4px;color: white;">{{leaderboard[1].userName}}</span>
                  <br />
                </span>
                <!-- 总金币数 -->
                <span style="color: white;vertical-align: middle;">{{leaderboard[1].num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </span>
            <span v-if="leaderboard != null && leaderboard.length >= 3">
              <!-- 第三名的默认头像 -->
              <span class="third-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第三名的头像 -->
              <span class="third-avatar" style="z-index: 2;">
                <img :src="leaderboard[2].avatar" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第三名的名字和总金币数 -->
              <span class="third-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[2].userName.length > 8">
                  <marquee scrolldelay="300" style="width: 60px;color: white;">{{leaderboard[2].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="display: inline-block;margin-bottom: 4px;color: white;">{{leaderboard[2].userName}}</span>
                  <br />
                </span>
                <!-- 总金币数 -->
                <span style="vertical-align: middle;color: white;">{{leaderboard[2].num}} </span>
                <img src="@/assets/common/cashIcon1.png" style="width: 20px;vertical-align: middle;" />
              </span>
            </span>
          </div>
          <div class="main-3">
            <div style="padding-top: 2%;"></div>
            <!-- 我的排行榜 -->
            <div v-if="myLeaderboard != null" style="background-color: rgba(32, 31, 45, 1);margin-top: 5px;overflow: auto;padding: 10px 20px;color: black;">
              <span style="width: 33px;float: left;vertical-align: middle;margin-top: 8px; color: rgba(255,255,255,1); margin-right: 4px; margin-left: -16px; text-align: right;">{{myLeaderboardIndex != null ? myLeaderboardIndex : '100+'}}</span>
              <span :style="{'text-align': 'left','float': 'left','width': (88 - (10 + (myLeaderboard.num + '').length * 3)) + '%','vertical-align': 'middle'}">
                <span style="position: relative;margin-left: 8px;margin-right: 8px;">
                  <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                  <img :src="myLeaderboard.avatar" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                </span>
                <span style="vertical-align: middle;width: 60%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;color: rgba(255, 255, 255, 1)">{{myLeaderboard.userName}}</span>
              </span>
              <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': (10 + (myLeaderboard.num + '').length * 3) + '%'}">
                <span style="vertical-align: middle; color: rgba(255, 255, 255, 1)">{{myLeaderboard.num}} </span>
                <img src="@/assets/common/cash1Icon.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </div>
            <!-- 排行榜 -->
            <div v-for="(leaderboard, index) in leaderboard" :key="index">
              <div v-if="index+1>3" style="margin-top: 10px;overflow: hidden;padding: 10px 20px;color: black;">
                <span style="width: 20px;float: left;vertical-align: middle;margin-top: 8px;color: rgba(255, 255, 255, 1)">{{index+1}}</span>
                <span :style="{'text-align': 'left','float': 'left','width': (88 - (10 + (leaderboard.num + '').length * 3)) + '%','vertical-align': 'middle'}">
                  <span style="position: relative;margin-left: 8px;margin-right: 8px;">
                    <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                    <img :src="leaderboard.avatar" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                  </span>
                  <span style="vertical-align: middle;width: 60%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;color: rgba(255, 255, 255, 1)">{{leaderboard.userName}}</span>
                </span>
                <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': (10 + (leaderboard.num + '').length * 3) + '%'}">
                  <span style="vertical-align: middle;color: rgba(255, 255, 255, 1)">{{leaderboard.num}} </span>
                  <img src="@/assets/common/cash1Icon.png" style="width: 20px;vertical-align: middle;" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="actLeaderboard==3" style="padding-bottom: 20px;">
          <div style="position: relative;margin-bottom: 5px;">
            <!-- 前三名排行榜背景图 -->
            <img src="@/assets/images/index/leaderboardIcon1.png" style="width: 310px;" />
            <span v-if="leaderboard != null && leaderboard.length >= 1">
              <!-- 皇冠 -->
              <span class="crown" style="z-index: 3;">
                <img src="@/assets/images/leaderboard002.png" style="width: 68px" />
              </span>
              <!-- 第一名的默认头像 -->
              <span class="first-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 68px;border-radius: 50px;" />
              </span>
              <!-- 第一名的头像 -->
              <span class="first-avatar" style="z-index: 2;">
                <img :src="leaderboard[0].avatar" style="width: 68px;border-radius: 50px;" />
              </span>
              <!-- 第一名的名字和总好友数 -->
              <span class="first-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[0].userName.length > 14">
                  <marquee scrolldelay="300" style="width: 90px;color: white;">{{leaderboard[0].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="color: white;display: inline-block;margin-bottom: 4px;">{{leaderboard[0].userName}}</span>
                  <br />
                </span>
                <!-- 总好友数 -->
                <span style="vertical-align: middle;color: white;">{{leaderboard[0].num}} </span>
                <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" alt="" />
              </span>
            </span>
            <span v-if="leaderboard != null && leaderboard.length >= 2">
              <!-- 第二名的默认头像 -->
              <span class="second-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第二名的头像 -->
              <span class="second-avatar" style="z-index: 2;">
                <img :src="leaderboard[1].avatar" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第二名的名字和总好友数 -->
              <span class="second-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[1].userName.length > 8">
                  <marquee scrolldelay="300" style="width: 60px;color: white;">{{leaderboard[1].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="color: white;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;margin-bottom: 4px;width: 60px;">{{leaderboard[1].userName}}</span>
                  <br />
                </span>
                <!-- 总好友数 -->
                <span style="vertical-align: middle;color: white;">{{leaderboard[1].num}} </span>
                <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" alt="" />
              </span>
            </span>
            <span v-if="leaderboard != null && leaderboard.length >= 3">
              <!-- 第三名的默认头像 -->
              <span class="third-avatar" style="z-index: 1;">
                <img src="@/assets/images/leaderboard004.png" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第三名的头像 -->
              <span class="third-avatar" style="z-index: 2;">
                <img :src="leaderboard[2].avatar" style="width: 52px;border-radius: 30px;" />
              </span>
              <!-- 第三名的名字和总好友数 -->
              <span class="third-name-gold">
                <!-- 名字 -->
                <div v-if="leaderboard[2].userName.length > 8">
                  <marquee scrolldelay="300" style="width: 60px;color: white;">{{leaderboard[2].userName}}</marquee>
                </div>
                <span v-else>
                  <span style="color: white;display: inline-block;margin-bottom: 4px;">{{leaderboard[2].userName}}</span>
                  <br />
                </span>
                <!-- 总好友数 -->
                <span style="vertical-align: middle;color: white;">{{leaderboard[2].num}} </span>
                <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" alt="" />
              </span>
            </span>
          </div>
          <div class="main-3">
            <div style="padding-top: 2%;"></div>
            <!-- 我的排行榜 -->
            <div v-if="myLeaderboard != null" style="background-color: rgba(32, 31, 45, 1));margin-top: 5px;overflow: auto;padding: 10px 20px;color: black;">
              <span style="width: 33px;float: left;vertical-align: middle;margin-top: 8px; color: rgba(255,255,255,1); margin-right: 4px; margin-left: -16px; text-align: right;">{{myLeaderboardIndex != null ? myLeaderboardIndex : '100+'}}</span>
              <div :style="{'text-align': 'left','float': 'left','width': (88 - (10 + (myLeaderboard.num + '').length * 3)) + '%','vertical-align': 'middle'}">
                <span style="position: relative;margin-left: 8px;margin-right: 8px;">
                  <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                  <img :src="myLeaderboard.avatar" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                </span>
                <span style="vertical-align: middle;width: 60%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis; color: rgba(255, 255, 255, 1)">{{myLeaderboard.userName}}</span>
              </div>
              <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': (10 + (myLeaderboard.num + '').length * 3) + '%'}">
                <span style="vertical-align: middle; color: rgba(255, 255, 255, 1)">{{myLeaderboard.num}} </span>
                <img src="@/assets/images/leaderboard003.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </div>
            <!-- 排行榜 -->
            <div v-for="(leaderboard, index) in leaderboard" :key="index">
              <div v-if="index+1>3" style="margin-top: 10px;overflow: auto;padding: 10px 20px;color: black;">
                <span style="width: 20px;float: left;vertical-align: middle;margin-top: 8px; color: rgba(255, 255, 255, 1)">{{index+1}}</span>
                <div :style="{'text-align': 'left','float': 'left','width': (88 - (10 + (leaderboard.num + '').length * 3)) + '%','vertical-align': 'middle'}">
                  <span style="position: relative;margin-left: 8px;margin-right: 8px;">
                    <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                    <img :src="leaderboard.avatar" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                  </span>
                  <span style="vertical-align: middle;width: 60%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis; color: rgba(255, 255, 255, 1)">{{leaderboard.userName}}</span>
                </div>
                <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': (10 + (leaderboard.num + '').length * 3) + '%'}">
                  <span style="vertical-align: middle; color: rgba(255, 255, 255, 1)">{{leaderboard.num}} </span>
                  <img src="@/assets/images/leaderboard003.png" style="width: 20px;vertical-align: middle;" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 返回顶部 -->
      <el-backtop :bottom="10" :right="0" :visibility-height="100" style="box-shadow: none;">
        <div style="height: 100%;width: 100%;text-align: center;line-height: 20px;">
          <svg t="1660880120995" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14866" width="32" height="32">
            <path d="M86 919.2c6.8 7.7 17.6 10.5 27.3 7l396.1-143.6 398.8 143.1c9.7 3.5 20.5 0.7 27.3-7.1 6.8-7.8 8.1-18.9 3.3-28L531.1 109.3c-4.3-8.3-12.9-13.4-22.2-13.4-9.3 0-17.9 5.2-22.2 13.5L82.6 891.3c-4.8 9.1-3.4 20.2 3.4 27.9z" p-id="14867" fill="rgba(48, 127, 95, 1)"></path>
          </svg>
        </div>
      </el-backtop>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{failMessage}}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      actLeaderboard: 1,
      leaderboard: [],
      myLeaderboard: {},
      myLeaderboardIndex: null
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    toTodayCashLeaderboard() {
      this.leaderboard = [];
      this.myLeaderboard = {};
      this.myLeaderboardIndex = null;
      this.isLoading = true;
      this.$axios({
        url: "/webCash/todayCashLeaderboard",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.leaderboard = result.data.data.leaderboard;
          this.myLeaderboard = result.data.data.myLeaderboard;
          this.myLeaderboardIndex = result.data.data.myLeaderboardIndex;
        } else {
          // this.$message({
          //   showClose: true,
          //   message: result.data.message,
          //   type: "error",
          //   center: true
          // });
        }
      });
    },
    toGoldLeaderboard() {
      this.leaderboard = [];
      this.myLeaderboard = {};
      this.myLeaderboardIndex = null;
      this.isLoading = true;
      this.$axios({
        url: "/webCash/cashLeaderboard",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.leaderboard = result.data.data.leaderboard;
          this.myLeaderboard = result.data.data.myLeaderboard;
          this.myLeaderboardIndex = result.data.data.myLeaderboardIndex;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    toFriendLeaderboard() {
      this.leaderboard = [];
      this.myLeaderboard = {};
      this.myLeaderboardIndex = null;
      this.isLoading = true;
      this.$axios({
        url: "/webCash/friendLeaderboard",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.leaderboard = result.data.data.leaderboard;
          this.myLeaderboard = result.data.data.myLeaderboard;
          this.myLeaderboardIndex = result.data.data.myLeaderboardIndex;
          console.log(this.userInfo)
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //this.$store.state.goldNum = this.userInfo.goldNum;
    this.failMessage = sessionStorage.getItem("failMessage");
    if (this.failMessage != null) {
      this.checkDialog = true;
    }
    this.toTodayCashLeaderboard();
  }
};
</script>

<style>
#leaderboard-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}
#leaderboard-layout .l-page-header {
  overflow: hidden;
  background-color: rgba(28, 29, 47, 1)
}
#leaderboard-layout .el-page-header,
#leaderboard-layout .el-page-header__content {
  color: white !important;
}
#leaderboard-layout .taskAct {
  background-color: rgba(62, 72, 103, 1) !important;
  color: white !important;
}
#leaderboard-layout .actShow {
  background-color: rgba(48, 127, 95, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
}
#leaderboard-layout .el-backtop {
  background-color: transparent !important;
}
/* 皇冠 */
#leaderboard-layout .crown {
  position: absolute;
  box-sizing: border-box;
  top: -46.5%;
  left: 0;
  right: 0;
  font-size: 12px;
  color: white;
}
/* 第一名头像 */
#leaderboard-layout .first-avatar {
  position: absolute;
  box-sizing: border-box;
  top: -28%;
  left: 0;
  right: 0;
  font-size: 12px;
  color: white;
}
/* 第一名名字和总金币 */
#leaderboard-layout .first-name-gold {
  position: absolute;
  box-sizing: border-box;
  top: 13%;
  left: 0;
  right: 0;
  font-size: 12px;
  color: white;
}
/* 第二名头像 */
#leaderboard-layout .second-avatar {
  position: absolute;
  box-sizing: border-box;
  top: -3%;
  left: -199px;
  right: 0;
  font-size: 12px;
  color: white;
}
/* 第二名名字和总金币 */
#leaderboard-layout .second-name-gold {
  position: absolute;
  box-sizing: border-box;
  top: 27%;
  left: -200px;
  right: 0;
  font-size: 12px;
  color: white;
}
/* 第三名头像 */
#leaderboard-layout .third-avatar {
  position: absolute;
  box-sizing: border-box;
  top: -3%;
  left: 199px;
  right: 0;
  font-size: 12px;
  color: white;
}
/* 第三名名字和总金币 */
#leaderboard-layout .third-name-gold {
  position: absolute;
  box-sizing: border-box;
  top: 27%;
  left: 200px;
  right: 0;
  font-size: 12px;
  color: white;
}

#leaderboard-layout .leaderboardAct {
  background-color: rgba(80, 270, 119, 1);
  color: rgba(35, 53, 63, 1)!important;
  border-radius: 30px;
}

@media screen and (min-width: 1280px){
  #leaderboard-layout .main-1{
    display: flex; 
    justify-content: left; 
    align-items: center; 
    margin-top: 35px; 
    /* margin-left: -1005px */
    width: 1200px;
  }

  #leaderboard-layout .main-2{
    display: flex; 
    justify-content: center;
    font-size: 12px;
    margin-top: 50px;
    margin-bottom: 8px; 
    background-color: rgba(32, 31, 45, 1); 
    border: 2px solid rgba(53, 109, 84, 1);
    width: 293px;
    border-radius:20px; 
    height: 35px
  }

  #leaderboard-layout .main-3{
    width: 1200px;
    margin: auto;
    padding-bottom: 23%;
  }
}

@media screen and (max-width: 1280px){
    
    #leaderboard-layout .main-1{
      display: flex; 
      justify-content: left; 
      align-items: center; 
      margin-top: 20px; 
      /* margin-left: -69%; */
      margin-bottom: 20px;
      width: 92%;
    }

    #leaderboard-layout .main-2{
    display: flex; 
    justify-content: center;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 36px; 
    background-color: rgba(32, 31, 45, 1); 
    border: 2px solid rgba(53, 109, 84, 1);
    width: 293px;
    border-radius:20px; 
    height: 35px
  }

  #leaderboard-layout .main-3{
    width: 92%;
    margin: auto;
    padding-bottom: 2%;
  }
}


</style>
