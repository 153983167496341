<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-layout">
      <div class="c-page-header" style="padding: 25px 11px 0 11px;">
        
        <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <span class="top-button">
              <div class="top-button1" style="display: flex; align-items: center; flex-wrap: nowrap;">
                  <span @click="$router.push('/index')" class="main-0">
                      <img src="@/assets/images/index/indexIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_home')}}</div>
                  </span>
                  
                  <span @click="$router.push('/redeem')"  class="main-0" style="background-color: rgba(80, 270, 119, 1);display: flex; flex-direction: column; align-items: center;display: flex; flex-direction: column; align-items: center; justify-content: center; width: 90px; height: 49px;">
                      <img src="@/assets/images/index/exchangeIcon1.png" style="width: 18px; vertical-align: middle;" />
                      <div style="color: rgba(0, 0, 0, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_redeem')}}</div>
                  </span>
                  
                  <span @click="$router.push('/earn')" class="main-0">
                      <img src="@/assets/images/index/earnIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_earnMoney')}}</div>
                  </span>
                  
                  <span @click="$router.push('/inviteFriend')" class="main-0">
                      <img src="@/assets/images/index/inviteIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_invite')}}</div>
                  </span>
                  
                  <span @click="$router.push('/account')" style="display: flex; flex-direction: column; align-items: center;">
                      <img src="@/assets/images/index/accountIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_account')}}</div>
                  </span>
              </div>
          </span>
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 6px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
      </div>

      <div style="margin-top: 10px">
          <swiper :options="swiperOption1"  class="my-swiper">
            <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <div style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Gabriel...</div>
                  </div>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Alexan...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">40PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlott...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/linePayIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">LinePay</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Mia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">10TWD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Victor...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/trueMoneyIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-left: -5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">True Money</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Sophia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">50THB</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlo...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">150PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1"  />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Benja...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
                      
          </swiper>
        </div>
      
           
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 30px; margin-bottom: 60px;">
        <div class="main-1">
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 60px; margin-bottom: 110px">
            <div style="width:160px; color: rgba(255, 255, 255, 1); font-weight: 700; letter-spacing: 2.24px; margin-right: 17%; margin-left: 21%; font-size: 20px">
              {{$t('all.i_redeemCash')}}
            </div>
            <img @click="$router.push('/convertLog')" src="@/assets/images/index/logIcon1.png" style="width: 25px; vertical-align: middle;"/>
          </div>

          <!-- <div style="padding-bottom: 6%;">
              <span v-for="giftCard in this.data.giftCardList" v-bind:key="giftCard.cardId">
                <router-link :to="{ name: 'convertInfo', params: { id: giftCard.cardId } }">
                  <img :src="giftCard.image" style="margin: 2px;width: 160px;border-radius: 8px;" />
                </router-link>
              </span>
            </div> -->

            <div class="main-1-2">
              <div style="width: 117px; height: 30px; margin-bottom: 139px; margin-right: 0px; display: flex; justify-content: center; align-items: center;" v-for="(giftCard) in this.data.giftCardList" :key="giftCard.cardId">
                    <div  style="display: flex;  align-items: center; flex-direction: column;background: rgba(64, 61, 95, 1); width: 112px; height: 155px; border-radius: 15px">
                      <router-link :to="{ name: 'convertInfo', params: { id: giftCard.cardId } }">
                        <img :src="giftCard.image" style="margin: 2px;width: 100px;border-radius: 8px; margin-top: 8px; margin-bottom: 45px;" />
                      </router-link>
                      <div style="color: rgba(255, 255, 255, 1); font-weight: 700; letter-spacing: 1.4px; font-size: 15px;">
                        {{giftCard.name}}
                      </div>
                    </div>
                  </div>     
            </div>
        </div> 
      </div>

        <div style="margin: auto; display: flex; justify-content: center; align-items: center; flex-direction: column; background-color: rgba(29, 30, 48, 1); height: 138px; margin-top: 370px;">
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1.09px; margin-bottom: 12px; font-size: 15px;">
              <span @click="openPolicy()" style="">《{{$t('index.i_privacyPolicy')}}》</span>
              <span @click="openService()" style="">《{{$t('index.i_termsOfService')}}》</span>
          </div>
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1px; font-size: 12px">
            © 2020 - 2024 BIgbig Cash 2. All rights reserved.
          </div>
        </div>

        <div style="height: 10vh"></div>
      </div>
    


    

      <!-- <div style="margin-top: 2%">Convert your Cash-Coupon to cash</div>

      <div style="margin-top: 5%;vertical-align: middle;">
        <img src="@/assets/common/cashIcon.png" style="width: 36px;vertical-align: middle;" />
        <span style="margin-left: 4px;font-size: 42px;font-weight: 800;color: rgba(211, 196, 25, 1);-webkit-text-stroke: 1.2px rgba(0, 0, 0, 1);vertical-align: middle;">{{ $store.state.cashNum }}</span>
      </div>

      <img src="@/assets/images/convertIcon.png" style="margin-top: 10%;width: 100px;" />

      <div style="margin-top: 14%;margin-bottom: 5%;margin-left: 5%;text-align: left;">
        {{ $t('convert.i_choose') }}
      </div> -->

      

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false,
        
      },
      failMessage: null,
      swiperOption1: {
         slidesPerView: 'auto',
         spaceBetween: -70,
         loop: true,
         grid: {
            fill: 'column',
            rows: 1,
         },
         speed:9500,
         freeMode:true,

            autoplay: {

              delay:0,
              disableOnInteraction: false,
            },
          loopAdditionalSlides: 1
      },
      data: {
        giftCardList: []
      },
      checkDialog: false
    };
  },
  methods: {
    openService() {
      window.open(window.location.origin+"/BigBigSoftTermsOfService.html", "_blank");
    },

    openPolicy() {
      window.open(window.location.origin+"/BigBigSoftPrivacyPolicy.html", "_blank");
    },
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    getGiftCardList() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/convert",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.data = result.data.data;
          if (result.data.data.giftCardList != null) {
            sessionStorage.setItem(
              "giftCardList",
              JSON.stringify(result.data.data.giftCardList)
            );
          } else {
            sessionStorage.removeItem("giftCardList");
          }
        }
      });
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //this.$store.state.goldNum = this.userInfo.goldNum;
    this.failMessage = sessionStorage.getItem("failMessage");
    if (this.failMessage != null) {
      this.checkDialog = true;
    }
    this.getGiftCardList();
  }
};
</script>

<style>
#convert-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}

#convert-layout .c-page-header {
  overflow: hidden;
  background-color: rgba(28, 29, 47, 1)
}

@media screen and (max-width: 1280px) {

  #convert-layout .main-1{
    background: rgba(34, 35, 55, 1); 
    width: 95%;
  }

  #convert-layout .main-1-2{
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center;
  margin:auto;
  /* gap: 10px; */
 }
 .top-1{
  float: left; width: 135px; 
  margin-bottom: 12px; 
  margin-left:10px
 }
 .top-2{
  float: right;
 }
}
 @media screen and (min-width: 1280px) {
  #convert-layout .my-swiper {
    width: 1200px; /*设置Swiper的宽度*/
    overflow: hidden; /* 隐藏溢出的内容 */
    margin: auto; /* 水平居中 */
  }
    #convert-layout .main-1-2{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center;
    width: 1100px; 
    margin:auto
  }

  #convert-layout .main-1{
    background: rgba(34, 35, 55, 1); 
    width: 1200px;
  }

  .top-1{
  float: left; width: 135px; 
  margin-bottom: 12px; 
  margin-left:50px
 }
 .top-2{
  float: right;
  margin-right: 60px;
 }
 }

 @media screen and (min-width: 768px) and (max-width: 1280px) {
    #convert-layout .main-1 {
        background: rgba(34, 35, 55, 1);
        width: 92%;
    }
}

 #convert-layout .swiper{
  width:230px !important;
  
}

</style>
