const en = {
  common: {
    i_loading: "Loading...",
    i_back: "Back",
    i_timedOut: "Request timed out, please check network",
    i_copy: "Copied",
    i_cancel: "Cancel",
    i_goBack: "Goback now",
    i_copyC: "Copy"
  },
  index: {
    i_coinsLog: "Coins record",
    i_totalCoins: "Total coins",
    i_convert: "Withdraw",
    i_convertMsg: "Coins for cash",
    i_inviteFriends: "Invite friends",
    i_inviteFriendsMsg: "Get more coins",
    i_inviteCode: "Code",
    i_accountC: "Account",
    i_account: "Account",
    i_leaderboard: "Ranking",
    i_contact: "Contact",
    i_browserOpen: "Copy to default Browser to open",
    i_language: "en",
    i_personal: "Personal Center",
    i_records: "Records",
    i_currentGold: "Current Gold",

    i_home: "Home",
    i_redeem: "Redeem",
    i_earnMoney: "Earn",
    i_invite: "Invite",
    i_earnOver: "Earn over $500",
    i_joinbbc2: "Join BigBig Cash 2",
    i_goodReviewsRate: "Good Reviews Rate",
    i_getCash: "Get 20% cash commission from friends",
    i_lotsOfCashTask: "Lots of cash Task",
    i_manyRedemption: "Many redemption options",
    i_cashRedemption: "Cash redemption method",
    i_playMobileGames: "Play mobile games to earn cash",
    i_discoverNewMobile: "Discover new mobile games, complete game missions and earn real cash. Turn your gaming sessions into a revenue stream and increase your earnings even more",
    i_startEarningMoney: "Start earning money",
    i_cashRewardsForInvitations: "Cash rewards for invitations",
    i_realTimeUpdating: "Real-time updating",
    i_inviteFriendsCommission: "Invite friends to receive 20% cash commission from friends",
    i_joinCompleteFilling: "When your friends join the app through your link and complete the new user tasks, you will be able to get a 20% commission from the gold coins they earn (the upper limit for each friend is 2,000 gold coins). You can also get 100 gold coins by filling in the friend invitation code.",
    i_inviteFriendsToMakeMoney: "Invite friends to make money",
    i_joinTheOfficialCommunity: "Join the official community",
    i_userHasReceivedCash: "User has received cash",
    i_goodReviews: "Good reviews",
    i_userFeedbackOnBigBigcash2: "User feedback on BigBig cash2",
    i_joinBibigCash2AndReview: "Join Bibig Cash 2 and Leave a review",
    i_review1: "It's pretty good. It's the source of my living expenses.",
    i_review2: "The pocket money I earn from Bigbig Cash 2 can support part of my living expenses",
    i_review3: "I made $10 playing games",
    i_review4: "Great, the game is fun and you can make money",
    i_review5: "I actually received the money.",
    i_review6: "Recommended, you can really get cash. I tried it and didn't expect to really receive money. It's great!!!",
    i_review7: "I have already received $350",
    i_review8: "Great, inviting friends is the fastest way to make money",
    i_review9: "I also found other money making app here",
    i_review10: "I can also receive cash from the recommended APP, which is great~",
    i_review11: "Still reliable",
    i_review12: "I can receive money, and I feel like I can still make money.",
    i_privacyPolicy: "Privacy Policy",
    i_termsOfService: "Terms of Service",
    i_login: "Sign in",
    i_ReadAndAgreedTo: "Sign in means you have read and agreed to",
    i_loginException: "Login exception",
    i_afterReview: "After review, it was found that your account is abnormal",
    i_completeTheFirstTask: "Please complete the first task first",
    i_goNow: "Go now",

  },
  convert: {
    i_convert: "Withdraw",
    i_convertLog: "Withdraw Record",
    i_convertT: "Convert your coins to cash",
    i_choose: "Please choose a redemption method",
    i_input: "Input",
    i_account: "Receiving account",
    i_editAccount: "Edit account",
    i_checkN: "No responsibility for wrong numbers",
    i_receivingName: "Receiving name",
    i_enterReceivingName: "Enter receiving name",
    i_select: "Select currency",
    i_currency: "Currency",
    i_pleaseSelect: "Please select",
    i_bindAccount: "Bind the Receiving account",
    i_enterReceivingAccount: "Enter receiving account",
    i_redeemNow: "Withdraw now",
    i_bindN: "After binding, it cannot be changed",
    i_bindNow: "Bind now",
    i_following: "The following",
    i_bindT:
      "The account is about to be bound to your account. It cannot be changed after binding. Are you sure?",
    i_bind: "Bind",
    i_changeNow: "Change now",
    i_changeT:
      "Modifications need to be reviewed by the administrator, and cannot be exchanged during the review period. Do you want to confirm the change?",
    i_newReceivingAccount: "New receiving account",
    i_modifyReceivingAccount: "Modify the receiving account",
    i_newReceivingName: "New receiving name",
    i_confirm: "Confirm",
    i_ok: "Ok",
    i_convertMsg: "Is under review...",
    i_info_ing: "Information under review",
    i_infoN: "Information review failed",
    i_tips: "Tips",
    i_activateMsg: "Please activate Premium Features",
    i_accountN: "Account cannot be empty",
    i_nameN: "Name cannot be empty",
    i_reviewSucceeded: "Review succeeded",
    i_underReview: "Under review",
    i_auditFailure: "Audit failure",
    i_invalid: "Invalid",
    i_remark: "Remark",
    i_checkConvertInfo: "Please confirm your info",
    i_applicationPeriod: "Application Period",
    i_credited: "Credited in 3 ~15 Days !"
  },
  inviteFriend: {
    i_inviteFriend: "Invite friends",
    i_inviteT: "Every friend you invite",
    i_reward: "Reward",
    i_commissionT: "Friends' coins",
    i_total: "Total",
    i_friends: "Friends ",
    i_text1: "Friends download app via your link",
    i_text2: "Friends login/enter your code",
    i_text3_1: "Friends Play Game -> you get ",
    i_text3_2: " friends' coins",
    i_code: "Invitation code",
    i_inviteNow: "Invite friends now",
    i_enterCode: "Enter the code",
    i_friendCode: "Fill in the friend invite code",
    i_code1: "Only 1 invitation code can be filled in",
    i_claimNow: "Claim it now",
    i_inviteLog: "Invitation record",
    i_notActivated: "Premium features are not activated",
    i_filledIn: "Filled in",
    i_noLog: "No data at the moment",
    i_newest: "Newest",
    i_biggest: "Biggest",
    i_tipsDialogMsg:
      "Your friends need to download the app by clicking your link to display the Withdraw entrance !!!",
    i_tipsDialogMsg2:
      "Your friends need to click on your link to enter the game and complete the initial task before they can successfully join the team.!!!",
    i_youHaveBeenInvited: "You have been invited",
    i_everyFriend: "(Every friend)",
    i_friendsCommission: "Friends Commission",
    i_shareYourLink: "Share your link",
    i_loginByLink: "Friends login to the website via your link",
    i_unlock: "Complete an \"Initial Task\"",
    i_ShareInvitationCode: "Share your invitation code",
    i_entersInvitationCode: "Your friend enters your invitation code in the app",
    i_receiceReward: "Fill in the friend invitation code to receive",
    i_FriendInvitationCode: "Only one friend invitation code can be filled in",
    i_shareLink: "Share link",
    i_alreadyInvited: "Already invited",
  },
  all: {
    i_initialTask: "Initial Task",
    i_download: "Download",
    i_downloadNo: "Download now",
    i_loginAccount1: "Sign-In the same",
    i_loginAccount2: "Google account",
    i_loginAccount3: "account",
    i_state: "State",
    i_completed: "Completed",
    i_undone: "UnCompleted",
    i_claimRewards: "Claim Rewards",
    i_advancedTasks: "Advanced Tasks",
    i_claim: "Claim",
    i_allTasksCompleted: "All score tasks have been completed",
    i_allTasksCompleted1: "All gold tasks have been completed",
    i_inviteTasks: "Invite Tasks",
    i_inviteFriendsJoin: "Invite friends to join in",
    i_friends: "Friends",
    i_inviteFriendsTeam: "Invite friends join your team",
    i_earnRewards: "Earn rewards",
    i_playGamesCash: "Earn over $500 dollar",
    i_joinNow: "Join now",
    i_todayReward: "Today's",
    i_totalReward: "Total",
    i_currentTeam: "Current team",
    i_invitationCode: "Invite code",
    i_inviteFriendsJoinTeam: "Invite friends join your team",
    i_everyFriendInvited: "For every friend invited",
    i_currentFriends: "Current Friends",
    i_currentRewards: "Current rewards",
    i_friendsRewardsThem: "When your friends get rewards, you can get them",
    i_commissionUpTo: "commission, up to",
    i_friendsClickJoinTask: "Friends click on the link to join in the task",
    i_friendsCompleteInitialTask: 'Friends complete the "Initial Task"',
    i_joinTeam: "Successfully join your team",
    i_newestFriends: "Newest Friends",
    i_bestFriends: "Best Friends",
    i_commission: "Commission",
    i_variousGifts: "Cash",
    i_redeemNow: "Redeem now",
    i_couponDetails: "Coupon details",
    i_rewardRecords: "Reward records",
    i_leaderboard: "Ranking",
    i_viewLeaderboard: "View More",
    i_task: "Offer Task",
    i_completeTasks: "Complete tasks to receive rewards",
    i_invite: "Invite",
    i_nickName: "Name",
    i_friendInvitesHisTeam:
      "Your friend invites you to join his team and receive cash! !",
    i_moreCash: "More cash! !",
    i_joinTask: "Join this task and receive",
    i_joinTeamNow: "Join the team now",
    i_playNow: "Play Now",
    i_useBrowser: "Please use the following browser",
    i_joined: "Joined",
    i_pleaseInitTask: 'Please complete the "Initial Tasks" first',
    i_expTsaks: "EXP Tasks",
    i_copyToBrowser: "Copy to default Browser to open",


    i_back: "back",
    i_latest: "Latest",
    i_hottest: "hottest",
    i_redeemCash: "Redeem cash",
    i_tips: "Tips",
    i_invitationData: "Invitation data",
    i_recommendToFriends: "Recommend to friends",
    i_rewardByInvitation: "Reward by invitation",
    i_joinFriendAndComplete: "When your friends join the app through your link and complete the new user tasks, you will get",
    i_theMaximumLimit: "The maximum limit for each friend is",
    i_coins: "Coins",
    i_yourInvitationLink: "Your invitation link",
    i_inviteFriends: "Invite friends",
    i_instructions: "Instructions",
    i_step1: "Invite your friends",
    i_step1Instructions: "Share your referral link with your friends and join our app",
    i_step2: "Your friend has completed activation",
    i_step2Instructions: "Your friend completes the new user task",
    i_step3: "Claim your reward",
    i_step3Instructions: "Every time your friends claim a reward, you can claim 20% of their reward",
    i_viewAll: "View All",
    i_DownloadtheToTasks: "Download the APP to view more tasks",
    i_downloadNow: "Download Now",
    i_apkInstallation: "*The APK installation package will have a warning prompt, just ignore it",
    i_checkTheDetails: "check the details",
    i_scoreTask: "Score Task",
    i_coinsTask: "Coins Task",


  },
  leaderboard: {
    i_totalCoins: "Total Coins",
    i_friends: "Friends"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default en;
