<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-layout">
      <div >
      <div style="">
        <img  src="@/assets/images/invite/main.png" style="width: 100%; margin-top: 22px;" />
        <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/invite/main.png" style="width: 100%;" />
      </div>
      <div class="invite-footer" style="margin-top: 6%;">
        <div class="invite-code" style="background-color: rgba(67, 158, 78, 1); width: 255px; margin: auto; border-radius: 12px; margin-top: 37px; height: 45px">
          <div style="position: relative; top: 5px" >
            <span style="color: rgba(255, 255, 255, 1); font-size: 18px; margin-right: 7px;">{{$t('inviteFriend.i_code')}}:</span>
            <span @click="copyVal($event.target.innerText)" style="color: rgba(255, 255, 255, 1); font-size: 18px">{{inviteCode}}</span>
            <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
              <img src="@/assets/images/invite/sharecopy.png" style="width: 15px;margin-bottom: 10px; " />
            </span>
          </div>

        </div>

        <div  style="width: 100%; display: flex; justify-content: center; " @click="goLogin()">
          <img src="@/assets/images/invite/getcash.png"
               alt="" style="width: 280px; top: 15px; left: 200px; margin-top: 58px" />

        </div>


      </div>

      <el-dialog :visible.sync="isDownloadDialog" width="76%" center>
        <div style="text-align: center;">
          <div style="font-size: 20px;color: black;">
            {{$t('invite.i_havaDownloaded')}}
            <br />
            “Bigbig Cash2”
          </div>
          <el-button round @click="goDownload()" style="background-color: rgba(0, 111, 255, 1);color: white;margin-top: 30px;width: 60%;">
            {{$t('invite.i_goDownload')}}
          </el-button>
          <div @click="goApp()" style="text-decoration: underline;margin-top: 20px;color: rgba(161, 161, 161, 1);">
            {{$t('invite.i_downloaded')}}
          </div>
        </div>
      </el-dialog>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      inviteCode: "",
      taskInfo: {}
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color: rgba(80, 214, 119, 1); height: 125vh");
  },
  beforeDestroy() {
    document
      .querySelector("body")
      .removeAttribute("style", "background-color: rgba(223, 227, 252, 1);");
  },
  methods: {
    copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
    goInvite() {
      this.isLoading = true;
      // if (this.$route.query.tid != null && this.$route.query.tid != "") {
        this.$axios({
          url: "/webCash/invite",
          method: "get",
          params: {
            tid: "d4ffff8a66b5561e3207780e58b8d004"
          }
        }).then((result) => {
          if (result.data.code == 1) {
            this.isLoading = false;
            this.taskInfo = result.data.data.taskInfo;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
            this.$router.push("/index");
          }
        });
      // }
    },
    goLogin() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$router.push({
          path: "/index",
          query: {
            referrer: this.$route.query.referrer
          }
        });
      } else {
        this.$router.push({
          path: "/index",
          query: {
            referrer: this.$route.query.referrer
          }
        });
      }
    }
  },
  created() {
    // this.goInvite();
    this.inviteCode = this.$route.query.referrer;
  }
};
</script>

<style>
#invite-layout {
  width: 100%;
  height: 108%;
  margin: auto;
  max-width: 500px;
  background-color: rgba(80, 214, 119, 1);
}
</style>
